import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import Services from "../views/Services";
import Glossary from "../views/Glossary";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import RessetPassword from "../views/RessetPassword.vue";
import Registration from "../views/Registration.vue";
import HowWastinaWorks from "../views/HowWastinaWorks";
import MembershipPlans from "../views/MembershipPlans";
import Imprint from "../views/Imprint";
import PrivacyPolicy from "../views/PrivacyPolicy";
// protected routes
import AdminIndex from "../views/protected/admin/index.vue";
// import AdminDashboard from "../views/protected/admin/dashboard.vue";
// admin plan
import AdminPlanIndex from "../views/protected/admin/plan/index.vue";
import AdminPlanDashboard from "../views/protected/admin/plan/dashboard.vue";
//admin member
import AdminMemberIndex from "../views/protected/admin/member/index.vue";
import AdminMemberDashboard from "../views/protected/admin/member/dashboard.vue";
import AdminMemberDetail from "../views/protected/admin/member/detail.vue";
import AdminMemberSetup from "../views/protected/admin/member/setup.vue";
// admin sponsor
import AdminSponsorIndex from "../views/protected/admin/sponsor/index.vue";
import AdminSponsorDashboard from "../views/protected/admin/sponsor/dashboard.vue";
import addSponsor from "../views/protected/admin/sponsor/addSponsor.vue";
import updateSponsor from "../views/protected/admin/sponsor/editSponsor.vue";
import addMemberSponsor from "../views/protected/admin/sponsor/addMember.vue";
import addPayment from "../views/protected/admin/sponsor/addPayment.vue";

// admin health providr
import AdminHealthProviderIndex from "../views/protected/admin/healthcare/index.vue";
import AdminHealthProviderDashboard from "../views/protected/admin/healthcare/dashboard.vue";

// sponsor
import SponsorIndex from "../views/protected/sponsor/index.vue";
import SponsorDashboard from "../views/protected/sponsor/dashboard.vue";
//
import SponsorMemberIndex from "../views/protected/sponsor/member/index.vue";
import SponsorMemberDashboard from "../views/protected/sponsor/member/dashboard.vue";

// member
import MemberIndex from "../views/protected/member/index.vue";
import MemberDasboard from "../views/protected/member/dashboard.vue";

import MemberPlanIndex from "../views/protected/member/plan/index.vue";
import MemberPlanDashboard from "../views/protected/member/plan/dashboard.vue";

import MemberExpensesIndex from "../views/protected/member/expenses/index.vue";
import MemberExpensesDashboard from "../views/protected/member/expenses/dashboard.vue";

import MemberPCPIndex from "../views/protected/member/pcp/index.vue";
import MemberPCPDashboard from "../views/protected/member/pcp/dashboard.vue";

// hospital
import HospitalIndex from "../views/protected/hospital/index.vue";
import HospitalDashboard from "../views/protected/hospital/dashboard.vue";

import HospitalMemberIndex from "../views/protected/hospital/member/index.vue";
import HospitalMemberDashboard from "../views/protected/hospital/member/dashboard.vue";

import HospitalPCPIndex from "../views/protected/hospital/pcp/index.vue";
import HospitalPCPDashboard from "../views/protected/hospital/pcp/dashboard.vue";

import HospitalExpenseIndex from "../views/protected/hospital/expense/index.vue";
import HospitalExpenseDashboard from "../views/protected/hospital/expense/dashboard.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/how-wastina-works",
    name: "HowWastinaWorks",
    component: HowWastinaWorks,
  },
  {
    path: "/membership-plans",
    name: "MembershipPlans",
    component: MembershipPlans,
  },
  {
    path: "/services",
    name: "services",
    component: Services,
  },
  {
    path: "/glossary",
    name: "glossary",
    component: Glossary,
  },
  {
    path: "/dashboard",
    // name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/hospital",
    component: HospitalIndex,
    children: [
      {
        path: "",
        name: "hospitalDashboard",
        component: HospitalDashboard,
      },
      {
        path: "member",
        component: HospitalMemberIndex,
        children: [
          {
            path: "",
            name: "hospitalMemberDashboard",
            component: HospitalMemberDashboard,
          },
        ],
      },
      {
        path: "pcp",
        component: HospitalPCPIndex,
        children: [
          {
            path: "",
            name: "hospitalPCPDashboard",
            component: HospitalPCPDashboard,
          },
        ],
      },
      {
        path: "expense",
        component: HospitalExpenseIndex,
        children: [
          {
            path: "",
            name: "hospitalExpenseDashboard",
            component: HospitalExpenseDashboard,
          },
        ],
      },
    ],
  },
  {
    path: "/member",
    component: MemberIndex,
    children: [
      {
        path: "",
        name: "memberDashboard",
        component: MemberDasboard,
      },
      {
        path: "expense",
        component: MemberExpensesIndex,
        children: [
          {
            path: "",
            name: "myExpenses",
            component: MemberExpensesDashboard,
          },
        ],
      },
      {
        path: "pcp",
        component: MemberPCPIndex,
        children: [
          {
            path: "",
            name: "pcp",
            component: MemberPCPDashboard,
          },
        ],
      },
      {
        path: "plan",
        component: MemberPlanIndex,
        children: [
          {
            path: "",
            name: "myPlans",
            component: MemberPlanDashboard,
          },
        ],
      },
    ],
  },
  {
    path: "/sponsor",
    component: SponsorIndex,
    children: [
      {
        path: "",
        name: "sponsorDashboard",
        component: SponsorDashboard,
      },
      {
        path: "update",
        name: "updateSponsorProfile",
        component: updateSponsor,
      },
      {
        path: "member",
        component: SponsorMemberIndex,
        children: [
          {
            path: "",
            name: "sponsorMember",
            component: SponsorMemberDashboard,
          },
        ],
      },
      {
        path: "addmember",
        component: addMemberSponsor,
        children: [
          {
            path: "",
            name: "addmembersponsorMember",
            component: addMemberSponsor,
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    component: AdminIndex,
    children: [
      {
        path: "",
        name: "adminPlanDashboard",
        component: AdminPlanDashboard,
      },
      {
        path: "plan",
        component: AdminPlanIndex,
        children: [
          {
            path: "",
            name: "adminPlanDashboard",
            component: AdminPlanDashboard,
          },
        ],
      },
      {
        path: "sponsor",
        component: AdminSponsorIndex,
        children: [
          {
            path: "",
            name: "adminSponsorDashboard",
            component: AdminSponsorDashboard,
          },
          {
            path: "add",
            name: "addSponsor",
            component: addSponsor,
          },
          {
            path: "update/:id",
            name: "editSponsor",
            component: updateSponsor,
          },
          {
            path: "addMember",
            name: "addMemberSponsor",
            component: addMemberSponsor,
          },
          {
            path: "addPayment",
            name: "addPaymentSponsor",
            component: addPayment,
          },
        ],
      },
      {
        path: "member",
        component: AdminMemberIndex,
        children: [
          {
            path: "",
            name: "adminMemberDashboard",
            component: AdminMemberDashboard,
          },
          {
            path: "detail/:memberId",
            name: "adminMemberDetail",
            component: AdminMemberDetail,
          },
          {
            path: "setup/:memberId",
            name: "adminMemberSetup",
            component: AdminMemberSetup,
          },
        ],
      },
      {
        path: "healthprovider",
        component: AdminHealthProviderIndex,
        children: [
          {
            path: "",
            name: "adminHealthProviderDashboard",
            component: AdminHealthProviderDashboard,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/reset-password/:token",
    name: "ressetPassword",
    component: RessetPassword,
  },
  {
    path: "/book-plan",
    name: "bookPlan",
    component: Registration,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

export default router;
