<template>
  <div class="w-full">
    payment option
    <div>{{ member.name }}</div>
    <div>{{ member.plan[0]._id }}</div>
    <div>{{ member.plan[0] }}</div>
    <div id="paypal-button" />
    <PayPal
      :amount="member.plan[0].totalCost.toString()"
      currency="USD"
      :client="credentials"
      env="sandbox"
      v-on:payment-authorized="paymentAuthorized"
      v-on:payment-completed="paymentCompleted"
      v-on:payment-cancelled="paymentCancelled"
    >
    </PayPal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PayPal from "vue-paypal-checkout";
export default {
  name: "plan",
  components: {
    PayPal,
  },
  data() {
    return {
      paypal: {
        sandbox:
          "AT7SOAM1e8GFYzx6QyZrdMvW4Jg8fPrhYA6-r7yELDkvXZ-BVWS1X4DpIJ4u8JK0LxUg9s--7F0MkfZ4",
        production: "<production client id>",
      },
      credentials: {
        sandbox:
          "AT7SOAM1e8GFYzx6QyZrdMvW4Jg8fPrhYA6-r7yELDkvXZ-BVWS1X4DpIJ4u8JK0LxUg9s--7F0MkfZ4",
        production: "<production client id>",
      },
      sponsorMemberId: null,
    };
  },

  async created() {
    let loggedInUser = localStorage.getItem("memberId");
    if (loggedInUser) {
      let parsedMember = JSON.parse(localStorage.getItem("memberId"));
      this.sponsorMemberId = parsedMember.memberId;
    }
    /// get member
    if (this.sponsorMemberId) {
      await this.doGetSingle(this.sponsorMemberId);
    }
  },
  computed: {
    ...mapGetters({
      message: "MemberStore/message",
      error: "MemberStore/error",
      color: "MemberStore/color",
      member: "MemberStore/member",
    }),
  },
  methods: {
    ...mapActions({
      doGetSingle: "MemberStore/doGetSingle",
      doUpdateStatus: "MemberStore/doUpdateStatus",
    }),
    async editStatus() {
      let payload = {
        mId: this.member._id,
        planId: this.member.plan[0]._id,
      };
      await this.doUpdateStatus(payload);
      console.log("payload" + JSON.stringify(payload));
    },
    paymentAuthorized: function (data) {
      console.log("paymentAuthorized");
      console.log(data);
    },
    paymentCompleted: function (data) {
      console.log("paymentCompleted");
      console.log(data);
      this.editStatus();
    },
    paymentCancelled: function (data) {
      console.log("paymentCancelled");
      console.log(data);
    },
  },
};
</script>

<style></style>
