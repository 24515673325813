<template>
  <div
    v-if="show"
    class="fixed inset-0 z-50 flex items-center justify-center w-full overflow-y-auto"
  >
    <div
      @click.self="close_modal()"
      class="fixed w-full h-screen bg-gray-900 modal-overlay opacity-70"
    ></div>

    <div
      class="z-50 w-full mx-auto bg-white rounded shadow-lg modal-container md:max-w-lg"
    >
      <div class="px-8 py-4 text-left modal-content">
        <div class="flex items-center justify-between py-2 gap-x-10">
          <h6 class="text-xl font-bold text-primary-blue">Edit member</h6>
          <div class="p-1 group hover:p-1 hover:bg-gray-100 hover:rounded-full">
            <svg
              @click="close_modal()"
              class="w-8 h-8 text-gray-600 cursor-pointer stroke-[1.5] group-hover:text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <form @submit.prevent>
          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Full Name</label>
              <input
                type="text"
                placeholder="First Name"
                v-model="memberSchema.name"
                v-on:change="resetErrorField('name')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.name !== ''"
                >{{ errorMessage.name }}</span
              >
            </div>

            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Email</label>
              <input
                type="text"
                placeholder="Email"
                v-model="memberSchema.email"
                v-on:change="resetErrorField('email')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.email !== ''"
                >{{ errorMessage.email }}</span
              >
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="gender" class="mb-2">Gender</label>
              <select
                v-model="memberSchema.gender"
                v-on:change="resetErrorField('gender')"
                class="border-[#E9EDF4] rounded py-3 px-5"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <span
                class="text-red-600"
                v-if="error && errorMessage.gender !== ''"
                >{{ errorMessage.gender }}</span
              >
            </div>
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Dob</label>
              <input
                type="date"
                placeholder="date of birth"
                v-model="memberSchema.dob"
                v-on:change="resetErrorField('dob')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.dob !== ''"
                >{{ errorMessage.dob }}</span
              >
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                v-model="memberSchema.phoneNumber"
                v-on:change="resetErrorField('phoneNumber')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.phoneNumber !== ''"
                >{{ errorMessage.phoneNumber }}</span
              >
            </div>

            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">City</label>
              <input
                type="text"
                placeholder="city"
                v-model="memberSchema.city"
                v-on:change="resetErrorField('city')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.city !== ''"
                >{{ errorMessage.city }}</span
              >
            </div>
          </div>

          <div class="flex flex-col mb-6">
            <label for="" class="mb-2">Country</label>
            <input
              type="text"
              placeholder="Country"
              v-model="memberSchema.country"
              v-on:change="resetErrorField('country')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-red-600"
              v-if="error && errorMessage.country !== ''"
              >{{ errorMessage.country }}</span
            >
          </div>
          <div class="flex justify-center">
            <button
              @click="submit_form"
              class="flex px-4 py-2 border border-gray-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EditMember",

  props: {
    show: Boolean,
    member: Object,
  },
  data() {
    return {
      memberSchema: {
        name: "",
        email: "",
        gender: "",
        dob: "",
        phoneNumber: "",
        city: "",
        country: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      message: "MemberStore/message",
      error: "MemberStore/error",
      color: "MemberStore/color",
    }),
  },

  methods: {
    ...mapActions({
      doEdit: "MemberStore/doEdit",
    }),
    close_modal() {
      this.$emit("close");
    },
    async submit_form() {
      await this.doEdit({
        _id: this.memberSchema._id,
        name: this.memberSchema.name,
        email: this.memberSchema.email,
        gender: this.memberSchema.gender,
        dob: this.memberSchema.dob,
        phoneNumber: this.memberSchema.phoneNumber,
        city: this.memberSchema.city,
        country: this.memberSchema.country,
      });
      console.log(this.error);
      if (this.error) {
        this.$toastr.e(this.message);
      } else {
        this.close_modal();
      }
    },
  },
  watch: {
    member: function () {
      this.memberSchema = { ...this.member };
    },
  },
};
</script>

<style scoped>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
