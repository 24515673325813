<template>
  <div
    v-if="show"
    class="fixed inset-0 z-50 flex items-center justify-center w-full overflow-y-auto"
  >
    <div
      @click.self="close_modal()"
      class="fixed w-full h-screen bg-gray-900 modal-overlay opacity-70"
    ></div>

    <div
      class="z-50 w-full mx-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div class="px-8 py-4 text-left modal-content">
        <div class="flex items-center justify-between py-2 gap-x-10">
          <h6 class="text-xl font-bold text-primary-blue">
            Edit Primary care physician
          </h6>
          <div class="p-1 group hover:p-1 hover:bg-gray-100 hover:rounded-full">
            <svg
              @click="close_modal()"
              class="w-8 h-8 text-gray-600 cursor-pointer stroke-[1.5] group-hover:text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <form @submit.prevent="submit_form">
          <div class="w-full bg-white">
            <div class="py-4">
              <div class="mb-6">
                <label
                  for="fname"
                  class="block text-xs font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  v-model="pcpSchema.firstName"
                  placeholder="First name"
                  required
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label
                  for="lname"
                  class="block text-xs font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  v-model="pcpSchema.lastName"
                  placeholder="Last name"
                  required
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label for="" class="block text-xs font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  required
                  type="text"
                  v-model="pcpSchema.phoneNumber"
                  placeholder="Phone number"
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label for="" class="block text-xs font-medium text-gray-700">
                  Email
                </label>
                <input
                  required
                  type="email"
                  v-model="pcpSchema.email"
                  placeholder="Email"
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
            </div>
            <div class="flex items-center justify-end gap-x-6">
              <div
                @click="close_modal()"
                class="px-4 py-2.5 text-sm font-semibold transition border rounded-md cursor-pointer hover:bg-gray-100"
              >
                Cancel
              </div>
              <button
                type="submit"
                value="SignIn"
                class="px-4 py-2.5 text-sm font-semibold text-white transition border rounded-md cursor-pointer bg-primary-blue hover:bg-blue-900"
              >
                Save pcp
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddPlan",

  props: {
    show: Boolean,
    pcp: Object,
  },
  data() {
    return {
      showAddModal: false,
      pcpSchema: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      message: "PcpStore/message",
      error: "PcpStore/error",
      color: "PcpStore/color",
      pcpByHospital: "PcpStore/pcpByHospital",
    }),
  },

  methods: {
    ...mapActions({
      doEdit: "PcpStore/doEdit",
    }),
    close_modal() {
      this.$emit("close");
    },
    async submit_form() {
      let data = {
        _id: this.pcpSchema._id,
        firstName: this.pcpSchema.firstName,
        lastName: this.pcpSchema.lastName,
        email: this.pcpSchema.email,
        phoneNumber: this.pcpSchema.phoneNumber,
      };
      await this.doEdit(data);
      if (this.error) {
        this.$toastr.e(this.message);
      } else {
        this.close_modal();
      }
    },
  },
  watch: {
    pcp: function () {
      this.pcpSchema = { ...this.pcp };
    },
  },
};
</script>

<style scoped>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
