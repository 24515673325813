import Api from "../../services/api";
export default {
  namespaced: true,
  state: {
    pcps: [],
    pcpByHospital: [],
    error: false,
    color: "",
    message: "",
  },
  actions: {
    async doGetAll({ commit }) {
      let response;
      try {
        response = await Api().get(`doctor/`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_PCP", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doGetAllByHospital({ commit }) {
      let response;
      try {
        response = await Api().get(`doctor/by-hospital/`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_PCP_BY_HOSPITAL", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },

    async setHeader() {
      console.log(Api());
    },

    async doCreate({ commit, dispatch }, payload) {
      let response;
      try {
        response = await Api().post(`doctor/`, { ...payload });
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAllByHospital");
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doEdit({ commit, dispatch }, payload) {
      let response;
      try {
        let { _id, ...data } = payload;
        response = await Api().put(`doctor/${_id}`, data);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAllByHospital");
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doDelete({ commit, dispatch }, id) {
      let response;
      try {
        response = await Api().delete(`doctor/${id}`);
        commit("SET_MESSAGE", response.message);
        commit("SET_ERROR", response.error);
        if (!response.error) {
          dispatch("doGetAllByHospital");
        }
      } catch (e) {
        commit("SET_ERROR", e.error);
        commit("SET_MESSAGE", e.message);
      }
    },
    async createSelectedPCP({ commit, dispatch }, payload) {
      let response;
      try {
        response = await Api().put(`/member/assign-pcp/${payload}/`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAllByHospital");
        }
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  getters: {
    message(state) {
      return state.message;
    },
    error(state) {
      return state.error;
    },
    color(state) {
      return state.color;
    },
    pcps(state) {
      return state.pcps;
    },
    pcpByHospital(state) {
      return state.pcpByHospital;
    },
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_PCP(state, payload) {
      state.pcps = payload;
    },
    SET_PCP_BY_HOSPITAL(state, payload) {
      state.pcpByHospital = payload;
    },
  },
};
