<template>
  <div>
    <div class="w-10/12 mx-auto lg:px-20 py-7" v-if="formDisplay === 'sponsor'">
      <form @submit.prevent>
        <div class="flex justify-between space-x-4">
          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">First Name</label>
            <input
              type="text"
              placeholder="First Name"
              v-model="sponsorSchema.firstName"
              v-on:change="resetSponsorErrorField('firstName')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-sm text-red-600"
              v-if="
                sponsorError &&
                sponsorErrorMessage.firstName !== '' &&
                !sponsorSchema.firstName
              "
            >
              <!-- {{ sponsorErrorMessage.firstName }} -->
              Required field
            </span>
          </div>
          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              v-model="sponsorSchema.lastName"
              v-on:change="resetSponsorErrorField('lastName')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-sm text-red-600"
              v-if="
                sponsorError &&
                sponsorErrorMessage.lastName !== '' &&
                !sponsorSchema.lastName
              "
            >
              <!-- {{ sponsorErrorMessage.lastName }} -->
              Required field
            </span>
          </div>
        </div>

        <div class="flex justify-between space-x-4">
          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">Email</label>
            <input
              type="text"
              placeholder="Email"
              v-model="sponsorSchema.email"
              v-on:change="resetSponsorErrorField('email')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-sm text-red-600"
              v-if="
                sponsorError &&
                sponsorErrorMessage.email !== '' &&
                !sponsorSchema.email
              "
            >
              <!-- {{ sponsorErrorMessage.email }} -->
              Required field
            </span>
          </div>
          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">StreetNumber</label>
            <input
              type="text"
              placeholder="Street Number"
              v-model="sponsorSchema.streetNumber"
              v-on:change="resetSponsorErrorField('streetNumber')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-sm text-red-600"
              v-if="
                sponsorError &&
                sponsorErrorMessage.streetNumber !== '' &&
                !sponsorSchema.streetNumber
              "
            >
              <!-- {{ sponsorErrorMessage.streetNumber }} -->
              Required field
            </span>
          </div>
        </div>

        <div class="flex justify-between space-x-4">
          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">City</label>
            <input
              type="text"
              placeholder="City"
              v-model="sponsorSchema.city"
              v-on:change="resetSponsorErrorField('city')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-sm text-red-600"
              v-if="
                sponsorError &&
                sponsorErrorMessage.city !== '' &&
                !sponsorSchema.city
              "
            >
              <!-- {{ sponsorErrorMessage.city }} -->
              Required field
            </span>
          </div>

          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">State</label>
            <input
              type="text"
              placeholder="State"
              v-model="sponsorSchema.state"
              v-on:change="resetSponsorErrorField('state')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-sm text-red-600"
              v-if="
                sponsorError &&
                sponsorErrorMessage.state !== '' &&
                !sponsorSchema.state
              "
            >
              <!-- {{ sponsorErrorMessage.state }} -->
              Required field
            </span>
          </div>
        </div>

        <div class="flex flex-col mb-6">
          <label for="" class="mb-2">Country</label>
          <input
            type="text"
            placeholder="Country"
            v-model="sponsorSchema.country"
            v-on:change="resetSponsorErrorField('country')"
            class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
          />
          <span
            class="text-sm text-red-600"
            v-if="
              sponsorError &&
              sponsorErrorMessage.country !== '' &&
              !sponsorSchema.country
            "
          >
            <!-- {{ sponsorErrorMessage.country }} -->
            Required field
          </span>
        </div>
        <div>
          <!-- <div class="flex items-center mb-6">
            <span>
              <input
                v-model="createAccount"
                type="checkbox"
                class="w-6 h-6 border-gray-300 rounded-md text-primary-blue"
              />
            </span>
            <span class="ml-2">Do you want to create an Account too</span>
          </div> -->
          <!-- v-if="createAccount" -->
          <div class="flex justify-between space-x-5">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Password</label>
              <input
                type="password"
                placeholder="******"
                v-model="sponsorSchema.password"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-sm text-red-600"
                v-if="
                  sponsorError &&
                  sponsorErrorMessage.password !== '' &&
                  !sponsorSchema.password
                "
              >
                <!-- {{ sponsorErrorMessage.country }} -->
                Required field
              </span>
            </div>
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Confirm Password</label>
              <input
                type="password"
                placeholder="********"
                v-model="confirmPassword"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span class="text-sm text-red-600" v-if="hasPasswordMismatch">
                Password mismatch
              </span>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <button
            @click="submit_form"
            class="px-6 py-2 text-white border border-gray-500 rounded bg-primary-blue hover:bg-blue-900"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
    <div v-if="formDisplay === 'member'" class="w-10/12 mx-auto lg:px-20 py-7">
      <div class="w-full">
        <!-- {{ sponsorToken }}
        {{ sponsor_id }} -->
        <form @submit.prevent>
          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Full Name</label>
              <input
                type="text"
                placeholder="First Name"
                v-model="memberSchema.name"
                v-on:change="resetMemberErrorField('name')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-sm text-red-600"
                v-if="
                  memberError &&
                  memberErrorMessage.name !== '' &&
                  !memberSchema.name
                "
              >
                <!-- {{ memberErrorMessage.name }} -->
                Required field
              </span>
            </div>

            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Email</label>
              <input
                type="text"
                placeholder="Email"
                v-model="memberSchema.email"
                v-on:change="resetMemberErrorField('email')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-sm text-red-600"
                v-if="
                  memberError &&
                  memberErrorMessage.email !== '' &&
                  !memberSchema.email
                "
              >
                <!-- {{ memberErrorMessage.name }} -->
                Required field
              </span>
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="gender" class="mb-2">Gender</label>

              <select
                v-model="memberSchema.gender"
                v-on:change="resetMemberErrorField('gender')"
                class="border-[#E9EDF4] rounded py-3 px-5"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <span
                class="text-sm text-red-600"
                v-if="
                  memberError &&
                  memberErrorMessage.gender !== '' &&
                  !memberSchema.gender
                "
              >
                <!-- {{ memberErrorMessage.gender }} -->
                Required field
              </span>
            </div>
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Dob</label>
              <input
                type="date"
                placeholder="date of birth"
                v-model="memberSchema.dob"
                v-on:change="resetMemberErrorField('dob')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-sm text-red-600"
                v-if="
                  memberError &&
                  memberErrorMessage.dob !== '' &&
                  !memberSchema.dob
                "
              >
                <!-- {{ memberErrorMessage.dob }} -->
                Required field
              </span>
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                v-model="memberSchema.phoneNumber"
                v-on:change="resetMemberErrorField('phoneNumber')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-sm text-red-600"
                v-if="
                  memberError &&
                  memberErrorMessage.phoneNumber !== '' &&
                  !memberSchema.phoneNumber
                "
              >
                <!-- {{ memberErrorMessage.phoneNumber }} -->
                Required field
              </span>
            </div>

            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">City</label>
              <input
                type="text"
                placeholder="city"
                v-model="memberSchema.city"
                v-on:change="resetMemberErrorField('city')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-sm text-red-600"
                v-if="
                  memberError &&
                  memberErrorMessage.city !== '' &&
                  !memberSchema.city
                "
              >
                <!-- {{ memberErrorMessage.city }} -->
                Required field
              </span>
            </div>
          </div>

          <div class="flex flex-col mb-6">
            <label for="" class="mb-2">Country</label>
            <input
              type="text"
              placeholder="Country"
              v-model="memberSchema.country"
              v-on:change="resetMemberErrorField('country')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-sm text-red-600"
              v-if="
                memberError &&
                memberErrorMessage.country !== '' &&
                !memberSchema.country
              "
            >
              <!-- {{ memberErrorMessage.country }} -->
              Required field
            </span>
          </div>
          <div>
            <div v-if="loading">loading</div>
            <div v-else>
              <div v-if="isPlanNonEmpty">
                <div v-for="(plan, index) in plans" :key="index" class="py-2">
                  <div
                    class="flex flex-col items-center justify-around space-y-8 bg-blue-100 rounded-lg hover:cursor-pointer"
                    :class="selectedPlanIndex == index ? `bg-blue-300` : ``"
                    @click="selectedPlanSetter(plan._id, index)"
                  >
                    <div class="relative w-full">
                      <div class="p-4">
                        <span
                          class="block mb-4 text-lg font-semibold capitalize text-primary-blue"
                        >
                          {{ plan.name }}
                        </span>
                        <p class="mb-5 text-dark">
                          {{ plan.price }}
                        </p>
                        <div class="">
                          <div
                            class="flex mb-4 space-x-2"
                            v-for="(description, index1) in plan.descriptions"
                            :key="index1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-5 h-5"
                              viewBox="0 0 20 20"
                              fill="#1d4ed8"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            <p class="text-sm text-gray-600">
                              {{ description.item }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="selectedPlanIndex == index"
                        class="w-full p-4 bg-blue-500 rounded"
                      >
                        <div class="flex justify-between">
                          <div class="flex">
                            <span class="mr-4">year</span>

                            <div class="flex items-center space-x-2">
                              <button @click.stop="decreaseYear">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="w-6 h-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </button>
                              <span> {{ year }}</span>

                              <button @click.stop="increaseYear">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="w-6 h-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div>
                            Total {{ calculateYearPrice(year, plan.price) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- {{ plan }} -->
              </div>
              <div v-else>no plan found</div>
            </div>
          </div>
          <div class="flex justify-center">
            <button
              @click="submit_form"
              class="px-6 py-2 text-white border border-gray-500 rounded bg-primary-blue hover:bg-blue-900"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { backendBaseUrl } from "../Api";
export default {
  name: "Registration",
  data() {
    return {
      // createAccount: false,
      formDisplay: "sponsor",
      confirmPassword: "",
      sponsorSchema: {
        firstName: "",
        lastName: "",
        email: "",
        streetNumber: "",
        city: "",
        state: "",
        country: "",
        password: "",
      },
      loading: true,
      year: 1,
      memberSchema: {
        name: "",
        email: "",
        gender: "",
        dob: "",
        phoneNumber: "",
        city: "",
        country: "",
      },
      plan: {
        SponsorId: null,
        planId: null,
        totalCost: 0,
        totalYear: 1,
      },
      selectedPlan: null,
      selectedPlanIndex: null,
      sponsorToken: null,
      sponsor_id: null,
    };
  },
  computed: {
    ...mapGetters({
      plans: "PlanStore/plans",
      member: "MemberStore/member",
      memberErrorMessage: "MemberStore/errorMessage",
      sponsorErrorMessage: "SponsorStore/errorMessage",
      sponsorResponseMessage: "SponsorStore/responseMessage",
      memberResponseMessage: "MemberStore/responseMessage",
      sponsorError: "SponsorStore/error",
      memberError: "MemberStore/error",
    }),
    isPlanNonEmpty() {
      return this.plans.length > 0 ? true : false;
    },
    hasPasswordMismatch() {
      if (
        (this.confirmPassword && this.sponsorSchema.password) ||
        !this.confirmPassword
      ) {
        return this.sponsorSchema.password !== this.confirmPassword;
      }
      return false;
    },
  },
  methods: {
    resetSponsorErrorField(key) {
      this.resetSponsorError(key);
    },
    resetMemberErrorField(key) {
      this.resetMemberError(key);
    },
    increaseYear() {
      this.year = this.year + 1;
    },
    decreaseYear() {
      if (this.year > 1) {
        this.year = this.year - 1;
      }
    },
    ...mapActions({
      createSponsor: "SponsorStore/doCreate",
      doGetAll: "PlanStore/doGetAll",
      createMemberBySponsor: "MemberStore/doCreateBySponsor",
      getMember: "MemberStore/doGetSingle",
      resetSponsorError: "SponsorStore/resetError",
      resetMemberError: "MemberStore/resetError",
    }),

    async submit_form() {
      if (this.formDisplay === "sponsor") {
        // this.sponsorSchema.password = this.password;
        // if (this.createAccount) {
        // }
        this.loading = true;
        if (!this.hasPasswordMismatch) {
          await this.createSponsor(this.sponsorSchema);
        }
        if (this.sponsorError === true && this.sponsorResponseMessage) {
          this.$toastr.e(this.sponsorResponseMessage);
        } else if (!this.sponsorError) {
          this.formDisplay = "member";
        }
      } else if (this.formDisplay === "member") {
        let loggedInUser = localStorage.getItem("loggedInUser");
        if (loggedInUser) {
          let parsedUser = JSON.parse(localStorage.getItem("loggedInUser"));
          this.sponsorToken = parsedUser.token;
          this.plan.SponsorId = parsedUser._id;
          this.sponsor_id = parsedUser._id;
        }

        this.memberSchema.plan = [this.plan];

        this.loading = true;
        await this.createMemberBySponsor(this.memberSchema);

        if (this.memberError === true && this.memberResponseMessage) {
          this.$toastr.e(this.memberResponseMessage);
        } else if (!this.memberError) {
          let memberData = JSON.parse(localStorage.getItem("memberId"));
          await this.getMember(memberData.memberId);
          let plan_id = this.member.plan[0]._id;
          window.location.href = `${backendBaseUrl}/sponsor/create-payment/${this.member._id}/${plan_id}`;
        }
      }
      this.loading = false;
    },
    selectedPlanSetter(PlanId, index) {
      if (this.selectedPlanIndex !== index) {
        this.year = 1;
      }
      this.plan.planId = PlanId;

      (this.selectedPlan = PlanId), (this.selectedPlanIndex = index);
    },
    calculateYearPrice(year, price) {
      this.plan.totalCost = year * price;
      return year * price;
    },
  },
  async created() {
    console.log(this.error);
    await this.doGetAll();
    this.loading = false;
  },
};
</script>

<style></style>
