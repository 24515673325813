<template>
  <div>
    <div class="container pt-[110px]">
      <h2 class="mb-2 text-2xl font-semibold text-black">
        {{ $t("our-story") }}
      </h2>
      <VueShowdown :markdown="`${story.content}`" />
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../../Api";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "OurStory",
  data() {
    return {
      // story: {},
      loadingImage: require("../../assets/curve-Loading.gif"),
      loading: true,
      error: null,
    };
  },
  created() {
    this.fetchStory();
  },
  computed: {
    ...mapGetters("getPages", ["lang"]),
    ...mapState("getPages", ["story"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchStory();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchStory"]),
    getBaseUrl() {
      return baseUrl;
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(this.api);
    //     this.story = response.data;
    //     this.loading = false;
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
  },
};
</script>

<style></style>
