vue.
<template>
  <section class="bg-[#F4F7FF] py-20">
    <div class="max-w-5xl px-4 mx-auto">
      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div class="px-8 py-8 text-left modal-content">
          <div class="flex justify-end"></div>
          <div class="flex items-center mb-3">
            <p class="text-2xl font-bold">Resset password</p>
          </div>

          <div class="flex justify-between space-x-5">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Password</label>
              <input
                type="password"
                placeholder="******"
                v-model="password"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
            </div>
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Confirm Password</label>
              <input
                type="password"
                placeholder="********"
                v-model="confirmPassword"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span class="text-sm text-red-600" v-if="hasPasswordMismatch">
                Password mismatch
              </span>
            </div>
          </div>
          <div class="mb-10">
            <button
              type="submit"
              value="Sign In"
              class="w-full px-5 py-3 text-base text-white transition bg-primary-blue border rounded-md cursor-pointer border-primary hover:bg-opacity-90"
              @click="submit_password"
            >
              update password
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "RessetPassword",

  props: {
    show: Boolean,
    url: String,
    label: String,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: "UserStore/errorMessage",
      responseMessage: "UserStore/responseMessage",
      error: "UserStore/error",
      color: "UserStore/color",
    }),
    hasPasswordMismatch() {
      if ((this.confirmPassword && this.password) || !this.confirmPassword) {
        return this.password !== this.confirmPassword;
      }
      return false;
    },
  },

  methods: {
    ...mapActions({
      resetError: "UserStore/resetError",
      ressetPassword: "UserStore/ressetPassword",
    }),
    resetErrorField(key) {
      this.resetError(key);
    },
    async submit_password() {
      let payload = {
        token: this.$route.params.token,
        password: this.password,
      };
      await this.ressetPassword(payload);
      if (this.error && this.responseMessage) {
        this.$toastr.e(this.responseMessage);
      } else if (!this.error) {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
