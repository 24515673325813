<template>
  <div class="px-6 relative pt-[120px] pb-[110px]">
    <div class="container mx-auto">
      <div class="w-full px-4">
        <div class="mb-12">
          <div
            class="grid grid-cols-1 gap-10 pb-20 mx-auto md:grid-cols-2 lg:grid-cols-3"
          >
            <div
              class="flex flex-col mb-4 text-base text-gray-600"
              v-for="(item, index) in wastina_how"
              :key="item.id"
            >
              <span
                class="relative z-10 mr-2 mb-3 flex h-10 w-full max-w-[40px] items-center justify-center rounded text-base text-white"
              >
                <span
                  class="absolute top-0 left-0 z-[-1] h-full w-full rounded-full bg-primary-blue"
                >
                </span>
                <span class="font-bold"> {{ index + 1 }}</span>
              </span>
              <VueShowdown :markdown="`${item.step}`" />
            </div>
          </div>
          <div>
            <p class="text-gray-600">
              Once a sponsored person becomes a member, a personal
              physician/primary care physician (PCP) will be assigned to them.
              There is no limit to how often and how many times a member can see
              their PCP. A member has the right to change their PCP. During the
              first visit to a PCP, a member will be offered a comprehensive
              annual general check-up, which will help them understand their
              overall health status.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div> -->
  </div>
</template>

<script>
import { baseUrl } from "../Api";

import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "HowWastinaWorks",
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
      // items: [],
    };
  },
  computed: {
    ...mapGetters("getPages", ["lang"]),
    ...mapState("getPages", ["wastina_how"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchWastinaHow();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchWastinaHow"]),
    getBaseUrl() {
      return baseUrl;
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(this.api);
    //     console.log("how wastina works : ", response.data);
    //     // let sorted = response.data.sort((a, b) => a.rank - b.rank);
    //     this.items = response.data;
    //     this.loading = false;
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
  },
  async created() {
    await this.fetchWastinaHow();
  },
};
</script>

<style></style>
