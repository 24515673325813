<template>
  <nav class="top-0 left-0 z-50 w-full px-6 py-2 bg-white shadow">
    <div class="container mx-auto lg:flex lg:flex-wrap lg:items-center">
      <div class="flex items-center justify-between lg:mr-10">
        <router-link to="/">
          <img
            src="../../assets/images/wastina_logo.svg"
            alt="wastina Logo"
            class="h-20"
          />
        </router-link>
        <LocaleSwitcher class="md:hidden" />
        <button
          @click="toggle"
          class="px-3 py-1 text-gray-600 hover:text-gray-800 lg:hidden"
          id="navbar-toggle"
        >
          <svg
            fill="none"
            stroke="currentColor"
            class="w-10 h-10"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      <LocaleSwitcher class="hidden md:block" />
      <div
        :class="open ? 'flex' : 'hidden'"
        class="flex-col mt-3 lg:flex lg:flex-row lg:ml-auto lg:mt-0"
        id="navbar-collapse"
      >
        <router-link
          v-for="item in navs"
          :key="item.name"
          @click.native="scrollToTop"
          :to="item.route"
          :class="item.class"
          class="p-2 py-2 text-2xl font-medium transition-colors duration-300 lg:text-sm text-dark lg:px-3 md:mx-2 hover:text-primary-blue"
        >
          {{ $t(`nav.${item.name}`) }}
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import LocaleSwitcher from "../LocaleSwitcher.vue";
export default {
  name: "Header",
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      navs: [
        {
          class: "",
          name: "home",
          route: "/",
        },
        {
          class: "",
          name: "about-us",
          route: "/about-us",
        },
        {
          class: "",
          name: "how-wastina-works",
          route: "/how-wastina-works",
        },
        {
          class: "",
          name: "membership-plans",
          route: "/membership-plans",
        },
        {
          class: "",
          name: "services",
          route: "/services",
        },
        {
          class: "",
          name: "glossary",
          route: "/glossary",
        },
        // {
        //   class: "",
        //   name: "dashboard",
        //   route: "/dashboard",
        // },
        {
          class: "",
          name: "login",
          route: "/login",
        },
        {
          class:
            "px-6 py-2 text-xl lg:text-base text-white hover:bg-white hover:border-primary-blue text-center transition border rounded-md cursor-pointer bg-primary-blue",
          name: "book-plan",
          route: "/book-plan",
        },
      ],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
