<template>
  <div class="w-full px-20 py-7">
    <form @submit.prevent>
      <div class="flex justify-between space-x-4">
        <div class="flex flex-col w-full mb-6">
          <label for="" class="mb-2">First Name</label>
          <input
            type="text"
            placeholder="First Name"
            v-model="sponsorSchema.firstName"
            v-on:change="resetErrorField('firstName')"
            class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
          />
          <span
            class="text-red-600"
            v-if="error && errorMessage.firstName !== ''"
            >{{ errorMessage.firstName }}</span
          >
        </div>
        <div class="flex flex-col w-full mb-6">
          <label for="" class="mb-2">Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            v-model="sponsorSchema.lastName"
            v-on:change="resetErrorField('lastName')"
            class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
          />
          <span
            class="text-red-600"
            v-if="error && errorMessage.lastName !== ''"
            >{{ errorMessage.lastName }}</span
          >
        </div>
      </div>

      <div class="flex justify-between space-x-4">
        <div class="flex flex-col w-full mb-6">
          <label for="" class="mb-2">Email</label>
          <input
            type="text"
            placeholder="Email"
            v-model="sponsorSchema.email"
            v-on:change="resetErrorField('email')"
            class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
          />
          <span
            class="text-red-600"
            v-if="error && errorMessage.email !== ''"
            >{{ errorMessage.email }}</span
          >
        </div>
        <div class="flex flex-col w-full mb-6">
          <label for="" class="mb-2">StreetNumber</label>
          <input
            type="text"
            placeholder="Street Number"
            v-model="sponsorSchema.streetNumber"
            v-on:change="resetErrorField('streetNumber')"
            class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
          />
          <span
            class="text-red-600"
            v-if="error && errorMessage.streetNumber !== ''"
            >{{ errorMessage.streetNumber }}</span
          >
        </div>
      </div>

      <div class="flex justify-between space-x-4">
        <div class="flex flex-col w-full mb-6">
          <label for="" class="mb-2">City</label>
          <input
            type="text"
            placeholder="City"
            v-model="sponsorSchema.city"
            v-on:change="resetErrorField('city')"
            class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
          />
          <span class="text-red-600" v-if="error && errorMessage.city !== ''">{{
            errorMessage.city
          }}</span>
        </div>

        <div class="flex flex-col w-full mb-6">
          <label for="" class="mb-2">State</label>
          <input
            type="text"
            placeholder="State"
            v-model="sponsorSchema.state"
            v-on:change="resetErrorField('state')"
            class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
          />
          <span
            class="text-red-600"
            v-if="error && errorMessage.state !== ''"
            >{{ errorMessage.state }}</span
          >
        </div>
      </div>

      <div class="flex flex-col mb-6">
        <label for="" class="mb-2">Country</label>
        <input
          type="text"
          placeholder="Country"
          v-model="sponsorSchema.country"
          v-on:change="resetErrorField('country')"
          class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
        />
        <span
          class="text-red-600"
          v-if="error && errorMessage.country !== ''"
          >{{ errorMessage.country }}</span
        >
      </div>
      <div>
        <div class="flex justify-between space-x-5">
          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">Password</label>
            <input
              type="text"
              placeholder="******"
              v-model="password"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
          </div>
          <div class="flex flex-col w-full mb-6">
            <label for="" class="mb-2">Confirm Password</label>
            <input
              type="text"
              placeholder="********"
              v-model="confirmPassword"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <button
          @click="submit_form"
          class="flex px-4 py-2 border border-gray-500 rounded"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan",
  data() {
    return {
      id: "",
      createAccount: false,
      password: "",
      confirmPassword: "",
      sponsorSchema: {
        firstName: "",
        lastName: "",
        email: "",
        streetNumber: "",
        city: "",
        state: "",
        country: "",
      },
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: "SponsorStore/errorMessage",
      responseMessage: "SponsorStore/responseMessage",
      error: "SponsorStore/error",
      color: "SponsorStore/color",
      sponsor: "SponsorStore/sponsor",
    }),
    isDataNonEmpty() {
      return this.sponsors.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      doGetAll: "SponsorStore/doGetAll",
      doEdit: "SponsorStore/doEdit",
      doCreateGuest: "SponsorStore/doCreateGuest",
      doGetOne: "SponsorStore/doGetOne",
      doGetProfile: "SponsorStore/doGetProfile",
      resetError: "SponsorStore/resetError",
    }),
    resetErrorField(key) {
      this.resetError(key);
    },
    async submit_form() {
      if (this.createAccount) {
        this.sponsorSchema.password = this.password;
        console.log(
          "this sponsorSchema =>" + JSON.stringify(this.sponsorSchema)
        );
      }
      this.loading = true;

      let data = {
        _id: this.id,
        firstName: this.sponsorSchema.firstName,
        lastName: this.sponsorSchema.lastName,
        email: this.sponsorSchema.email,
        streetNumber: this.sponsorSchema.streetNumber,
        city: this.sponsorSchema.city,
        state: this.sponsorSchema.state,
        country: this.sponsorSchema.country,
      };
      if (this.sponsorSchema.password !== "")
        data.password = this.sponsorSchema.password;
      await this.doEdit(data);

      if (this.error && this.responseMessage) {
        this.$toastr.e(this.responseMessage);
      } else if (!this.error) {
        this.loading = false;
        let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser.role == "sponsor")
          this.$router.push({ name: "sponsorDashboard" });
        else this.$router.push({ name: "adminSponsorDashboard" });
      }
    },
  },
  async created() {
    this.id = this.$route.params.id;
    if (!this.id) {
      // await this.doGetProfile();
      this.id = this.sponsor._id;
    }
    await this.doGetOne(this.id);
    this.sponsorSchema = { ...this.sponsor };
  },
};
</script>

<style></style>
