import Api from "../../services/api";
import Role from "../../services/constants";
import router from "@/router/index";
export default {
  namespaced: true,
  state: {
    users: [],
    error: false,
    color: "",
    responseMessage: "",
    errorMessage: "",
    user: {},
    loggedInUser: false,
  },
  actions: {
    resetError({ commit }, payload) {
      console.log(payload);
      commit("RESET_ERROR_MESSAGE", payload);
    },
    async login({ commit }, payload) {
      try {
        let response;
        response = await Api().post("/user/login", { ...payload.data });
        console.log("response =>" + JSON.stringify(response));
        commit("SET_RESPONSE_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_LOGGEDINUSER", true);
          let localStorageValue = {
            token: response.data.token,
            role: response.data.role,
          };
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(localStorageValue)
          );

          // member
          if (localStorage.loggedInUser) {
            let loggedInUserRole = JSON.parse(localStorage.loggedInUser).role;
            console.log("loggedin role", loggedInUserRole);
            if (loggedInUserRole === Role.Sponsor) {
              router.replace("/sponsor");
            } else if (loggedInUserRole === Role.SystemAdmin) {
              // router.replace("/dashboard");
              router.replace("/admin");
            } else if (loggedInUserRole === Role.Member) {
              router.replace("/member");
            } else if (loggedInUserRole === Role.Hospital) {
              router.replace("/hospital");
            }

            //hospital
          }
        }
      } catch (e) {
        let message = e.response.data.message;
        if (typeof message === "string") {
          commit("SET_ERROR_MESSAGE", {});
          commit("SET_RESPONSE_MESSAGE", message);
        } else {
          commit("SET_ERROR_MESSAGE", message);
          commit("SET_RESPONSE_MESSAGE", "");
        }
        commit("SET_ERROR", e.response.data.error);
      }
    },

    async logout({ commit }) {
      localStorage.removeItem("loggedInUser");
      commit("SET_LOGGEDINUSER", false);
      // router.replace("/");
      router.replace("/login");
    },

    async forgotPassword({ commit }, payload) {
      let response;
      try {
        response = await Api().post(`user/forgot-password/`, { ...payload });
        console.log("response=>", response);
        if (!response.data.error) {
          commit("SET_ERROR", false);
          commit("SET_RESPONSE_MESSAGE", response.data.message);
        }
      } catch (e) {
        console.log(e);
        let message = e.response.data.message;
        if (typeof message === "string") {
          commit("SET_ERROR_MESSAGE", {});
          commit("SET_RESPONSE_MESSAGE", message);
        } else {
          commit("SET_ERROR_MESSAGE", message);
          commit("SET_RESPONSE_MESSAGE", "");
        }
        commit("SET_ERROR", e.response.data.error);
      }
    },
    async ressetPassword({ commit }, payload) {
      let response;
      try {
        response = await Api().post(`user/reset-password/`, { ...payload });
        console.log("response=>" + JSON.stringify(response));
        if (!response.data.error) {
          commit("SET_ERROR", false);
        }
      } catch (e) {
        let message = e.response.data.message;
        console.log(message);
        if (typeof message === "string") {
          commit("SET_ERROR_MESSAGE", {});
          commit("SET_RESPONSE_MESSAGE", message);
        } else {
          commit("SET_ERROR_MESSAGE", message);
          commit("SET_RESPONSE_MESSAGE", "");
        }
        commit("SET_ERROR", e.response.data.error);
      }
    },
  },

  getters: {
    responseMessage(state) {
      return state.responseMessage;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    error(state) {
      return state.error;
    },
    color(state) {
      return state.color;
    },
    users(state) {
      return state.users;
    },
    loggedInUser(state) {
      return state.loggedInUser;
    },
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_RESPONSE_MESSAGE(state, message) {
      state.responseMessage = message;
    },
    SET_ERROR_MESSAGE(state, message) {
      state.errorMessage = message;
    },
    RESET_ERROR_MESSAGE(state, key) {
      state.errorMessage[key] = "";
    },
    SET_USER(state, payload) {
      state.users = payload;
    },
    SET_LOGGEDINUSER(state, payload) {
      state.loggedInUser = payload;
    },
  },
};
