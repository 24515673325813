import Api from "../../services/api";
export default {
  namespaced: true,
  state: {
    healthcares: [],
    error: false,
    color: "",
    message: "",
  },
  actions: {
    async doGetAll({ commit }) {
      let response;
      try {
        response = await Api().get(`healthcare/`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_HEALTHCARES", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },

    async doCreate({ commit, dispatch }, payload) {
      let response;
      try {
        response = await Api().post(`healthcare/`, { ...payload });
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAll");
        }
      } catch (e) {
        commit("SET_ERROR", e.response.data.error);
        commit("SET_MESSAGE", e.response.data.message);
      }
    },
    async doEdit({ commit, dispatch }, payload) {
      let response;
      try {
        let { _id, ...data } = payload;
        response = await Api().put(`healthcare/${_id}`, data);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAll");
        }
      } catch (e) {
        commit("SET_ERROR", e.response.data.error);
        commit("SET_MESSAGE", e.response.data.message);
      }
    },
    async doDelete({ commit, dispatch }, id) {
      let response;
      try {
        response = await Api().delete(`healthcare/${id}`);
        commit("SET_MESSAGE", response.message);
        commit("SET_ERROR", response.error);
        if (!response.data.error) {
          dispatch("doGetAll");
        }
      } catch (e) {
        commit("SET_ERROR", e.error);
        commit("SET_MESSAGE", e.message);
      }
    },
  },
  getters: {
    message(state) {
      return state.message;
    },
    error(state) {
      return state.error;
    },
    color(state) {
      return state.color;
    },
    healthcares(state) {
      return state.healthcares;
    },
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_HEALTHCARES(state, payload) {
      state.healthcares = payload;
    },
  },
};
