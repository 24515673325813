import strapiApi from "@/services/strapiApi";

export const namespaced = true;

export const state = {
  lang: localStorage.getItem("lang") || "en",
  services: [],
  glossary: {},
  heros: [],
  hospitals: [],
  partners: [],
  address: {},
  faq: [],
  story: {},
  about: {},
  mission: {},
  plans: [],
  wastina_how: [],
  portals: [],
};

export const mutations = {
  SET_LANG(state, lang) {
    state.lang = lang;
  },
  SET_SERVICES(state, services) {
    state.services = services;
  },
  SET_GLOSSARY(state, glossary) {
    state.glossary = glossary;
  },
  SET_HEROS(state, heros) {
    state.heros = heros;
  },
  SET_HOSPITALS(state, hospitals) {
    state.hospitals = hospitals;
  },
  SET_PARTNERS(state, partners) {
    state.partners = partners;
  },
  SET_ADDRESS(state, address) {
    state.address = address;
  },
  SET_FAQ(state, faq) {
    state.faq = faq;
  },
  SET_STORY(state, story) {
    state.story = story;
  },
  SET_ABOUT(state, about) {
    state.about = about;
  },
  SET_MISSION(state, mission) {
    state.mission = mission;
  },
  SET_PLANS(state, plans) {
    state.plans = plans;
  },
  SET_HOW_WASTINA_WORKS(state, wastina_how) {
    state.wastina_how = wastina_how;
  },
  SET_PORTALS(state, portals) {
    state.portals = portals;
  },
};

export const actions = {
  changeLanguage({ commit }, lang) {
    commit("SET_LANG", lang);
  },
  fetchServices({ commit, state }) {
    strapiApi.getServices(state.lang).then((response) => {
      commit(
        "SET_SERVICES",
        response.data.filter((s) => s.image)
      );
    });
  },
  fetchGlossary({ commit, state }) {
    strapiApi.getGlossary(state.lang).then((response) => {
      commit("SET_GLOSSARY", response.data);
    });
  },
  fetchHeros({ commit, state }) {
    strapiApi.getHeros(state.lang).then((response) => {
      commit("SET_HEROS", response.data);
    });
  },
  fetchHospitals({ commit }) {
    strapiApi.getHospitals().then((response) => {
      commit("SET_HOSPITALS", response.data);
    });
  },
  fetchPartners({ commit }) {
    strapiApi.getPartners().then((response) => {
      commit("SET_PARTNERS", response.data);
    });
  },
  fetchAddress({ commit, state }) {
    strapiApi.getAddress(state.lang).then((response) => {
      commit("SET_ADDRESS", response.data);
    });
  },
  fetchFaq({ commit, state }) {
    strapiApi.getFaq(state.lang).then((response) => {
      commit("SET_FAQ", response.data);
    });
  },
  fetchStory({ commit, state }) {
    strapiApi.getStory(state.lang).then((response) => {
      commit("SET_STORY", response.data);
    });
  },
  fetchAbout({ commit, state }) {
    strapiApi.getAbout(state.lang).then((response) => {
      commit("SET_ABOUT", response.data);
    });
  },
  fetchMission({ commit, state }) {
    strapiApi.getMission(state.lang).then((response) => {
      commit("SET_MISSION", response.data);
    });
  },
  fetchMembershipPlans({ commit, state }) {
    strapiApi.getMembershipPlans(state.lang).then((response) => {
      commit("SET_PLANS", response.data);
    });
  },
  fetchPortals({ commit, state }) {
    strapiApi.getPortals(state.lang).then((response) => {
      commit("SET_PORTALS", response.data);
    });
  },
  fetchWastinaHow({ commit, state }) {
    strapiApi.getWastinaHow(state.lang).then((response) => {
      let how_wastina_works = response.data.sort((a, b) => a.order - b.order);
      commit("SET_HOW_WASTINA_WORKS", how_wastina_works);
    });
  },
};

export const getters = {
  lang(state) {
    return state.lang;
  },
};
