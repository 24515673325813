<template>
  <div class="flex-1 w-full p-8">
    <div v-if="loading">loading</div>
    <div v-else class="flex flex-col w-full">
      <button @click="$router.go(-1)" class="flex py-4">Back</button>
      <div v-if="member" class="flex w-full">
        <div class="w-1/2">
          <div>Name:{{ member.name }}</div>
          <div>Gender:{{ member.gender }}</div>
          <div>Bob:{{ member.dob }}</div>
          <div>Email:{{ member.email }}</div>
          <div>Phone Number:{{ member.phoneNumber }}</div>
          <div>Account Active:{{ member.settedup }}</div>
          <div>City:{{ member.city }}</div>
          <div>Country:{{ member.country }}</div>
          <div>Status:{{ member.status }}</div>
          <div>Plan</div>
          <!-- <div>{{ member.plan }}</div> -->
        </div>
        <div class="w-1/2 px-10">
          <form @submit.prevent>
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Password</label>
              <input
                type="text"
                placeholder="password"
                v-model="password"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
            </div>
            <div class="flex items-center justify-center">
              <button @click="setUpMembership" class="bg-blue-400 px-4 py-2">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
      <div v-else>no data sorry</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan",
  data() {
    return {
      loading: true,
      password: "",
    };
  },
  async created() {
    this.loading = true;
    await this.doGetSingle(this.$route.params.memberId);
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      message: "MemberStore/message",
      error: "MemberStore/error",
      color: "MemberStore/color",
      member: "MemberStore/member",
    }),
  },
  methods: {
    ...mapActions({
      doGetSingle: "MemberStore/doGetSingle",
      doSetup: "MemberStore/doSetup",
    }),
    async setUpMembership() {
      let payload = {
        mId: this.member._id,
        password: this.password,
      };
      console.log("====================================");
      console.log(JSON.stringify(payload));
      console.log("====================================");
      await this.doSetup(payload);
      await this.doGetSingle(this.$route.params.memberId);
      this.$router.push("/admin/member");
    },
  },
};
</script>

<style></style>
