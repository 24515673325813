<template>
  <main>
    <div class="flex items-center justify-between px-10 py-7">
      <div>
        <h1 class="text-2xl font-semibold leading-relaxed text-gray-800">
          Members
        </h1>
      </div>
      <button
        @click="addMember"
        class="inline-flex items-center px-3 py-2.5 text-white rounded-md shadow-sm gap-x-1 bg-primary-blue hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="flex-shrink-0 w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span class="text-sm font-semibold tracking-wide">Add member</span>
      </button>
    </div>
    <div>
      <div v-if="loading" class="px-10 py-7">loading</div>
      <div v-else>
        <div v-if="isPcpNonEmpty" class="px-10">
          <table class="w-full divide-y divide-gray-300">
            <thead>
              <tr
                class="text-sm font-medium text-gray-700 border-b border-gray-200"
              >
                <th
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Full Name
                </th>
                <th
                  class="text-sm font-semibold text-left text-gray-900 px-3 py-3.5"
                >
                  Phone Number
                </th>
                <th class="text-sm font-semibold text-gray-900 px-3 py-3.5">
                  Plans
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                v-for="(member, index) in members"
                :key="index"
                class="transition-colors hover:bg-gray-100 group"
              >
                <td
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  {{ member.name }}
                </td>
                <td class="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                  {{ member.phoneNumber }}
                </td>
                <td
                  class="px-3 py-2 text-sm text-center text-gray-500 whitespace-nowrap"
                >
                  <span v-for="(plan, i) in member.plan" :key="i">
                    Plan: {{ plan.planId.name }} Price:
                    {{ plan.totalCost }} Status: {{ plan.status }}
                    <button
                      class="px-3 ml-3 text-center border border-green-500 rounded-full hover:bg-green-200"
                      @click="payPlan(member._id, plan._id)"
                    >
                      <span class="text-xs font-semibold"> Pay</span>
                    </button>
                    <button
                      class="px-3 ml-3 text-center border border-blue-500 rounded-full hover:bg-blue-200"
                      member-id="memeber._id"
                      plan-id=" plan._id"
                      @click="upgradePlan(member._id, plan)"
                    >
                      <span class="text-xs font-semibold"> Upgrade plan</span>
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="px-10">no data</div>
      </div>
    </div>
    <UpgradePlanModal
      :show="showUpgradeModal"
      @close="showUpgradeModal = false"
      :data="upgradeData"
    />
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { backendBaseUrl } from "../../../../Api.js";
import UpgradePlanModal from "./UpgradePlanModal.vue";

export default {
  components: {
    UpgradePlanModal,
  },
  data() {
    return {
      loading: true,
      showUpgradeModal: false,
      upgradeData: {},
    };
  },
  async created() {
    await this.doGetAll();
    let loggedInUser = localStorage.getItem("loggedInUser");
    if (loggedInUser) {
      let parsedUser = JSON.parse(localStorage.getItem("loggedInUser"));
      console.log("parsed user", parsedUser);
    }
    this.loading = true;
    await this.doGetBySponsor();
    this.loading = false;
  },

  computed: {
    ...mapGetters({
      plans: "PlanStore/plans",
      message: "MemberStore/message",
      error: "MemberStore/error",
      color: "MemberStore/color",
      members: "MemberStore/membersBySponsor",
    }),
    isPcpNonEmpty() {
      return this.members.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      doGetAll: "PlanStore/doGetAll",
      doGetBySponsor: "MemberStore/doGetBySponsor",
    }),
    payPlan(member, plan) {
      window.location.href = `${backendBaseUrl}/sponsor/create-payment/${member}/${plan}`;
    },
    upgradePlan(member, plan) {
      let existingPlan = this.plans.find((p) => p._id === plan.planId._id);
      this.upgradeData = {
        member: member,
        plan: plan._id,
        plans: this.plans.filter((p) => p.price > existingPlan.price),
      };
      this.showUpgradeModal = true;
    },
    addMember() {
      this.$router.push({ name: "addmembersponsorMember" });
    },
  },
};
</script>

<style></style>
