<template>
  <div id="app">
    <div id="nav" class="sticky top-0 z-50">
      <div v-if="loggedInUser">
        <DashboardHeader />
      </div>
      <Header v-else />
    </div>
    <router-view />
    <div>
      <div v-if="loggedInUser"></div>
      <Footer v-else />
    </div>
  </div>
</template>
<script>
import Header from "./components/common/Header";
import DashboardHeader from "./components/common/DashboardHeader";
import Footer from "./components/common/Footer";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    Header,
    Footer,
    DashboardHeader,
  },
  data() {
    return {
      loggedIn: true,
      // view: {
      //    atTopOfPage: true,
      // },
      //loggedInUser
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "UserStore/loggedInUser",
    }),
  },
};
</script>
<style lang="scss"></style>
