import axios from "axios";
// import NProgress from "nprogress";
import { baseUrl } from "@/Api.js";

const apiClient = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getServices(lang) {
    return apiClient.get(`/services?_locale=${lang}`);
  },
  getGlossary(lang) {
    return apiClient.get(`/glossary?_locale=${lang}`);
  },

  getHeros(lang) {
    return apiClient.get(`/hero?_locale=${lang}`);
  },
  getHospitals() {
    return apiClient.get(`/hospitals`);
  },
  getPartners() {
    return apiClient.get(`/partners`);
  },
  getAddress(lang) {
    return apiClient.get(`/address?_locale=${lang}`);
  },
  getFaq(lang) {
    return apiClient.get(`/faqs?_locale=${lang}`);
  },
  getStory(lang) {
    return apiClient.get(`/our-story?_locale=${lang}`);
  },
  getAbout(lang) {
    return apiClient.get(`/about?_locale=${lang}`);
  },
  getMission(lang) {
    return apiClient.get(`/mission?_locale=${lang}`);
  },
  // getFooters(lang) {
  //   return `${baseUrl}/footers?_locale=${lang}`;
  // },
  getMembershipPlans(lang) {
    return apiClient.get(`/membership-plans?_locale=${lang}`);
  },
  getWastinaPortals(lang) {
    return apiClient.get(`/portals?_locale=${lang}`);
  },
  getWastinaHow(lang) {
    return apiClient.get(`/how-wastina-works?_locale=${lang}`);
  },
  getWastinaPlans(lang) {
    return apiClient.get(`/wastina-plans?_locale=${lang}`);
  },
};
