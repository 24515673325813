<template>
  <div class="container pt-[110px]">
    <h2 class="mb-2 text-2xl font-semibold text-black">{{ $t("mission") }}</h2>
    <VueShowdown :markdown="`${mission.content}`" />
  </div>
</template>

<script>
import { baseUrl } from "../../Api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "Mission",
  data() {
    return {
      loading: true,
      error: null,
    };
  },
  created() {
    this.fetchMission();
  },
  computed: {
    ...mapState("getPages", ["mission"]),
    ...mapGetters("getPages", ["lang"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchMission();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchMission"]),
    getBaseUrl() {
      return baseUrl;
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(this.api);
    //     this.mission = response.data;
    //     this.loading = false;
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
  },
};
</script>

<style></style>
