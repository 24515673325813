<template>
  <div class="w-full px-20 py-7">
    <div class="flex space-x-4">
      <div class="w-1/2">
        <!-- {{ sponsorToken }}
        {{ sponsor_id }} -->
        <form @submit.prevent>
          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Full Name</label>
              <input
                type="text"
                placeholder="First Name"
                v-model="memberSchema.name"
                v-on:change="resetErrorField('name')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.name !== ''"
                >{{ errorMessage.name }}</span
              >
            </div>

            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Email</label>
              <input
                type="text"
                placeholder="Email"
                v-model="memberSchema.email"
                v-on:change="resetErrorField('email')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.email !== ''"
                >{{ errorMessage.email }}</span
              >
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="gender" class="mb-2">Gender</label>
              <select
                v-model="memberSchema.gender"
                v-on:change="resetErrorField('gender')"
                class="border-[#E9EDF4] rounded py-3 px-5"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <span
                class="text-red-600"
                v-if="error && errorMessage.gender !== ''"
                >{{ errorMessage.gender }}</span
              >
            </div>
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Dob</label>
              <input
                type="date"
                placeholder="date of birth"
                v-model="memberSchema.dob"
                v-on:change="resetErrorField('dob')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.dob !== ''"
                >{{ errorMessage.dob }}</span
              >
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                v-model="memberSchema.phoneNumber"
                v-on:change="resetErrorField('phoneNumber')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.phoneNumber !== ''"
                >{{ errorMessage.phoneNumber }}</span
              >
            </div>

            <div class="flex flex-col w-full mb-6">
              <label for="" class="mb-2">City</label>
              <input
                type="text"
                placeholder="city"
                v-model="memberSchema.city"
                v-on:change="resetErrorField('city')"
                class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
              />
              <span
                class="text-red-600"
                v-if="error && errorMessage.city !== ''"
                >{{ errorMessage.city }}</span
              >
            </div>
          </div>

          <div class="flex flex-col mb-6">
            <label for="" class="mb-2">Country</label>
            <input
              type="text"
              placeholder="Country"
              v-model="memberSchema.country"
              v-on:change="resetErrorField('country')"
              class="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
            />
            <span
              class="text-red-600"
              v-if="error && errorMessage.country !== ''"
              >{{ errorMessage.country }}</span
            >
          </div>
          <div class="flex justify-center">
            <button
              @click="submit_form"
              class="flex px-4 py-2 border border-gray-500"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
      <div class="flex flex-col w-1/2">
        <div v-if="loading">loading</div>
        <div v-else>
          <div v-if="isPlanNonEmpty">
            <div v-for="(plan, index) in plans" :key="index" class="py-2">
              <div
                class="flex flex-col items-center justify-around space-y-8 bg-blue-100 rounded-lg hover:cursor-pointer"
                :class="selectedPlanIndex == index ? `bg-blue-300` : ``"
                @click="selectedPlanSetter(plan._id, index)"
              >
                <div class="relative w-full">
                  <div class="p-4">
                    <span
                      class="block mb-4 text-lg font-semibold capitalize text-primary-blue"
                    >
                      {{ plan.name }}
                    </span>
                    <span
                      class="block mb-4 text-lg font-semibold capitalize text-primary-blue"
                    >
                      {{ plan.title }}
                    </span>
                    <p class="mb-5 text-dark">
                      {{ plan.price }}
                    </p>
                    <div class="">
                      <div
                        class="flex mb-4 space-x-2"
                        v-for="(description, index1) in plan.descriptions"
                        :key="index1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="#1d4ed8"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <p class="text-sm text-gray-600">
                          {{ description.item }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedPlanIndex == index"
                    class="w-full p-4 bg-blue-500 rounded"
                  >
                    <div class="flex justify-between">
                      <div class="flex">
                        <span class="mr-4">year</span>

                        <div class="flex items-center space-x-2">
                          <button @click.stop="decreaseYear">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                          <span> {{ year }}</span>

                          <button @click.stop="increaseYear">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        Total {{ calculateYearPrice(year, plan.price) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- {{ plan }} -->
          </div>
          <div v-else>no plan found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan",
  data() {
    return {
      year: 1,
      memberSchema: {
        name: "",
        email: "",
        gender: "",
        dob: "",
        phoneNumber: "",
        city: "",
        country: "",
      },
      plan: {
        SponsorId: null,
        planId: null,
        totalCost: 0,
        totalYear: 1,
      },
      loading: true,
      selectedPlan: null,
      selectedPlanIndex: null,
      sponsorToken: null,
      sponsor_id: null,
    };
  },
  watch: {
    year(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.plan.totalYear = newValue;
      }
    },
  },
  async created() {
    this.loading = true;
    await this.doGetAll();
    let loggedInUser = localStorage.getItem("loggedInUser");
    if (loggedInUser) {
      let parsedUser = JSON.parse(localStorage.getItem("loggedInUser"));
      if (loggedInUser.role === "sponsor") {
        delete this.sponsorToken;
        delete this.plan.SponsorId;
        delete this.sponsor_id;
      } else {
        this.sponsorToken = parsedUser.token;
        this.plan.SponsorId = parsedUser._id;
        this.sponsor_id = parsedUser._id;
      }
    }
    console.log("loggedInUser =>" + JSON.stringify(loggedInUser));
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      errorMessage: "MemberStore/errorMessage",
      responseMessage: "MemberStore/responseMessage",
      error: "MemberStore/error",
      color: "MemberStore/color",
      plans: "PlanStore/plans",
    }),
    isPlanNonEmpty() {
      return this.plans.length > 0 ? true : false;
    },
  },

  methods: {
    increaseYear() {
      this.year = this.year + 1;
    },
    decreaseYear() {
      if (this.year > 1) {
        this.year = this.year - 1;
      }
    },
    ...mapActions({
      doGetAll: "PlanStore/doGetAll",
      setHeader: "PlanStore/setHeader",
      doCreate: "MemberStore/doCreate",
      doCreateBySponsor: "MemberStore/doCreateBySponsor",
      resetError: "MemberStore/resetError",
    }),
    resetErrorField(key) {
      this.resetError(key);
    },
    async submit_form() {
      this.memberSchema.plan = [this.plan];
      console.log(JSON.stringify(this.memberSchema));
      this.loading = true;

      let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      console.log("loged in user--------->", loggedInUser.role);
      if (loggedInUser.role === "sponsor") {
        await this.doCreateBySponsor(this.memberSchema);
      } else {
        await this.doCreate(this.memberSchema);
      }

      if (this.error === true && this.responseMessage) {
        this.$toastr.e(this.responseMessage);
      } else if (!this.error) {
        this.$router.push({ name: "sponsorMember" });
      }
      this.loading = false;
    },
    selectedPlanSetter(PlanId, index) {
      if (this.selectedPlanIndex !== index) {
        this.year = 1;
      }
      this.plan.planId = PlanId;

      (this.selectedPlan = PlanId), (this.selectedPlanIndex = index);
    },
    calculateYearPrice(year, price) {
      this.plan.totalCost = year * price;
      return year * price;
    },
  },
};
</script>

<style></style>
