<template>
  <div>
    <Hero />
    <Partners />
    <FAQ />
  </div>
</template>

<script>
import Hero from "../components/home/Hero.vue";
import Partners from "../components/home/Partners.vue";
import FAQ from "../components/home/FAQ.vue";

export default {
  name: "Home",
  components: {
    Hero,
    FAQ,
    Partners,
  },
  created() {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser.role === "SystemAdmin") {
      this.$router.push("/admin");
    } else {
      this.$router.push(`/${loggedInUser.role}`);
    }
  },
};
</script>
<style></style>
