<template>
  <div class="flex-1 w-full p-8">
    <div v-if="loading">loading</div>
    <div v-else class="flex flex-col">
      <button @click="$router.go(-1)" class="flex py-2">Back</button>
      <div v-if="member">
        <div>Name:{{ member.name }}</div>
        <div>Gender:{{ member.gender }}</div>
        <div>Bob:{{ member.dob }}</div>
        <div>Phone Number:{{ member.phoneNumber }}</div>
        <div>Settedup:{{ member.settedup }}</div>
        <div>City:{{ member.city }}</div>
        <div>Country:{{ member.country }}</div>
        <div>Status:{{ member.status }}</div>
        <div>Plan</div>
        <div>{{ member.plan }}</div>
      </div>
      <div v-else>no data sorry</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan",
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    await this.doGetSingle(this.$route.params.memberId);
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      message: "MemberStore/message",
      error: "MemberStore/error",
      color: "MemberStore/color",
      member: "MemberStore/member",
    }),
  },
  methods: {
    ...mapActions({
      doGetSingle: "MemberStore/doGetSingle",
    }),
  },
};
</script>

<style></style>
