<template>
  <div
    v-if="show"
    class="fixed inset-0 z-50 flex items-center justify-center w-full overflow-y-auto"
  >
    <div
      @click.self="close_modal()"
      class="fixed w-full h-screen bg-gray-900 modal-overlay opacity-70"
    ></div>

    <div
      class="z-50 w-full mx-auto bg-white rounded shadow-lg modal-container md:max-w-lg"
    >
      <div class="px-8 py-4 text-left modal-content">
        <div class="flex items-center justify-between py-2 gap-x-10">
          <h6 class="text-xl font-bold text-primary-blue">Upgrade plan</h6>
          <div class="p-1 group hover:p-1 hover:bg-gray-100 hover:rounded-full">
            <svg
              @click="close_modal()"
              class="w-8 h-8 text-gray-600 cursor-pointer stroke-[1.5] group-hover:text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>

        <div class="flex flex-row">
          <div v-if="isPlanNonEmpty">
            <div v-for="(plan, index) in data.plans" :key="index" class="py-2">
              <div
                class="flex items-center justify-around bg-blue-100 rounded-lg hover:cursor-pointer"
                :class="selectedPlanIndex == index ? `bg-blue-300` : ``"
                @click="selectedPlanSetter(plan._id, index)"
              >
                <div class="relative">
                  <div class="p-4">
                    <span
                      class="block mb-4 text-lg font-semibold capitalize text-primary-blue"
                    >
                      {{ plan.name }}
                    </span>
                    <span
                      class="block mb-4 text-lg font-semibold capitalize text-primary-blue"
                    >
                      {{ plan.title }}
                    </span>
                    <p class="mb-5 text-dark">
                      {{ plan.price }}
                    </p>
                    <div class="">
                      <div
                        class="flex mb-4 space-x-2"
                        v-for="(description, index1) in plan.descriptions"
                        :key="index1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="#1d4ed8"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <p class="text-sm text-gray-600">
                          {{ description.item }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedPlanIndex == index"
                    class="w-full p-4 bg-blue-500 rounded"
                  >
                    <div class="flex justify-between">
                      <div class="flex">
                        <span class="mr-4">year</span>

                        <div class="flex items-center space-x-2">
                          <button @click.stop="decreaseYear">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                          <span> {{ year }}</span>

                          <button @click.stop="increaseYear">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        Total {{ calculateYearPrice(year, plan.price) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- {{ plan }} -->
          </div>
          <div v-else>no plan found</div>
        </div>

        <div class="flex items-center justify-end gap-x-6">
          <div
            @click="close_modal()"
            class="px-4 py-2.5 text-sm font-semibold transition border rounded-md cursor-pointer hover:bg-gray-100"
          >
            Cancel
          </div>
          <button
            type="submit"
            @click="submit_form"
            value="SignIn"
            class="px-4 py-2.5 text-sm font-semibold text-white transition border rounded-md cursor-pointer bg-primary-blue hover:bg-blue-900"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpgradePlanModal",

  props: {
    show: Boolean,
    data: Object,
  },
  data() {
    return {
      year: 1,
      new_plan: {
        SponsorId: null,
        planId: null,
        totalCost: 0,
        totalYear: 1,
      },
      selectedPlan: null,
      selectedPlanIndex: null,
      sponsorToken: null,
      sponsor_id: null,
    };
  },
  computed: {
    ...mapGetters({
      message: "SponsorStore/message",
      error: "SponsorStore/error",
      color: "SponsorStore/color",
    }),
    isPlanNonEmpty() {
      return this.data.plans.length > 0 ? true : false;
    },
  },

  methods: {
    increaseYear() {
      this.year = this.year + 1;
    },
    decreaseYear() {
      if (this.year > 1) {
        this.year = this.year - 1;
      }
    },
    ...mapActions({
      doUpgradePlan: "SponsorStore/doUpgradePlan",
    }),
    close_modal() {
      this.$emit("close");
    },
    async submit_form() {
      await this.doUpgradePlan({
        member: this.data.member,
        plan: this.data.plan,
        new_plan: this.new_plan.planId,
        totalYear: this.year,
        totalCost: this.new_plan.totalCost,
      });
      console.log(this.error);
      if (this.error) {
        this.$toastr.e(this.message);
      } else {
        this.close_modal();
      }
    },
    selectedPlanSetter(PlanId, index) {
      if (this.selectedPlanIndex !== index) {
        this.year = 1;
      }
      this.new_plan.planId = PlanId;

      (this.selectedPlan = PlanId), (this.selectedPlanIndex = index);
    },
    calculateYearPrice(year, price) {
      this.new_plan.totalCost = year * price;
      return year * price;
    },
  },
  watch: {
    plan: function () {
      this.planSchema = { ...this.plan };
      console.log(this.plan);
      console.log(this.planSchema);
    },
  },
};
</script>

<style scoped>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
