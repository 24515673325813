<template>
  <div
    v-if="show"
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full modal"
  >
    <div
      @click.self="close_modal()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div class="px-8 py-8 text-left modal-content">
        <div class="flex justify-end">
          <svg
            @click="close_modal()"
            class="w-8 h-8 text-gray-500 cursor-pointer hover:text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div class="flex items-center mb-3">
          <p class="text-2xl font-bold">Submit your gmail</p>
        </div>

        <div class="flex flex-wrap -mx-4">
          <div class="w-full px-4">
            <div class="py-16 text-center bg-white">
              <div class="mb-6">
                <input
                  type="text"
                  placeholder="Email"
                  v-model="email"
                  v-on:change="resetErrorField('email')"
                  class="w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none border-gray-300 focus:border-primary focus-visible:shadow-none"
                />
                <span
                  class="text-red-600"
                  v-if="error && errorMessage.email !== ''"
                  >{{ errorMessage.email }}</span
                >
              </div>
              <div class="mb-10">
                <button
                  class="w-full px-5 py-3 text-base text-white transition bg-primary-blue border rounded-md cursor-pointer border-primary hover:bg-opacity-90"
                  @click="submit_form"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ForgotPassword",

  props: {
    show: Boolean,
  },
  data() {
    return {
      email: "",
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: "UserStore/errorMessage",
      responseMessage: "UserStore/responseMessage",
      error: "UserStore/error",
      color: "UserStore/color",
    }),
  },

  methods: {
    ...mapActions({
      forgotPassword: "UserStore/forgotPassword",
      resetError: "UserStore/resetError",
    }),
    resetErrorField(key) {
      this.resetError(key);
    },
    close_modal() {
      this.$emit("close");
    },
    async submit_form() {
      console.log("first");
      let payload = {
        email: this.email,
      };
      await this.forgotPassword(payload);
      if (this.error && this.responseMessage) {
        this.$toastr.e(this.responseMessage);
      } else if (!this.error) {
        this.$toastr.s(this.responseMessage);
        this.close_modal();
      }
    },
  },
};
</script>

<style scoped>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
