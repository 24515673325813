<template>
  <section>
    <div class="bg-[#F3F4F6] px-6 pt-[120px] pb-[110px] text-gray-600">
      <div class="max-w-4xl mx-auto">
        <div class="container">
          <h2 class="mb-2 text-2xl font-semibold text-black">
            {{ $t("about-wastina") }}
          </h2>
          <VueShowdown :markdown="`${about.content}`" />
        </div>
        <our-story />
        <Mission></Mission>
      </div>
    </div>
    <!-- <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div> -->
  </section>
</template>
<script>
import { baseUrl } from "../Api";
import Mission from "../components/about/Mission.vue";
import ourStory from "../components/about/OurStory.vue";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "About",
  components: {
    ourStory,
    Mission,
  },
  data() {
    return {
      // about: {},
      loadingImage: require("../assets/curve-Loading.gif"),
      // loading: true,
      error: null,
    };
  },
  created() {
    this.fetchAbout();
  },
  computed: {
    ...mapGetters("getPages", ["lang"]),
    ...mapState("getPages", ["about"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchAbout();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchAbout"]),
    getBaseUrl() {
      return baseUrl;
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(this.api);
    //     console.log("abouts: ", response.data);
    //     this.about = response.data;
    //     this.loading = false;
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
  },
};
</script>
