<template>
  <main>
    <button
      style="float: right; margin-top: 5%"
      @click="updatePrifile"
      class="px-3 py-2.5 text-white rounded-md shadow-sm gap-x-1 bg-gray-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
    >
      <span class="text-sm font-semibold tracking-wide">update profile</span>
    </button>
    <div class="flex items-center justify-between px-10 py-7">
      <div>
        <h1 class="text-2xl font-semibold leading-relaxed text-gray-800"></h1>
        <div class="grid grid-cols-2 gap-8">
          <div>
            <span>Name</span>
          </div>
          <div>
            <span>{{ sponsor.firstName }} {{ sponsor.lastName }}</span>
          </div>
          <div>
            <span>Email </span>
          </div>
          <div>
            <span>{{ sponsor.email }}</span>
          </div>
          <div>
            <span>{{ sponsor.country }}</span>
          </div>
          <div>
            <span>{{ sponsor.country }}</span>
          </div>
          <div>
            <span>state </span>
          </div>
          <div>
            <span>{{ sponsor.state }}</span>
          </div>
          <div>
            <span>city </span>
          </div>
          <div>
            <span>{{ sponsor.city }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      sponsor: "SponsorStore/sponsor",
    }),
  },
  methods: {
    ...mapActions({
      doGetProfile: "SponsorStore/doGetProfile",
    }),
    updatePrifile() {
      this.$router.push({ name: "updateSponsorProfile" });
    },
  },
  async created() {
    await this.doGetProfile();
    console.log(this.sponsor);
  },
};
</script>

<style></style>
