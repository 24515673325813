<template>
  <div>
    <div class="relative">
      <button
        @click="isOpen = !isOpen"
        class="relative z-10 block w-10 h-10 overflow-hidden border-2 border-gray-600 rounded-full focus:outline-none focus:border-white"
      >
        <img
          class="object-cover w-full h-full"
          src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&q=80"
          alt=""
        />
      </button>
      <button
        v-if="isOpen"
        @click="isOpen = false"
        tabindex="-1"
        class="fixed inset-0 w-full h-full bg-transparent cursor-default"
      ></button>
      <div
        v-if="isOpen"
        class="absolute right-0 w-48 py-2 mt-2 bg-white rounded-lg shadow-xl"
      >
        <a
          href="#"
          class="block px-4 py-2 text-gray-800 hover:bg-primary-blue hover:text-white"
          >Account settings</a
        >
        <a
          href="#"
          class="block px-4 py-2 text-gray-800 hover:bg-primary-blue hover:text-white"
          >Support</a
        >
        <button
          @click="logout_action"
          class="flex w-full px-4 py-2 text-gray-800 hover:bg-primary-blue hover:text-white"
        >
          Sign out
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AccountDropdown",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    ...mapActions({
      logout: "UserStore/logout",
    }),
    async logout_action() {
      await this.logout();
      // window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped></style>
