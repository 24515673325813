<template>
  <div class="flex w-full min-h-screen font-sans text-gray-900 bg-gray-50">
    <SideBar />
    <router-view />
  </div>
</template>

<script>
import SideBar from "@/components/dashboard/sponsor/SideBar.vue";
export default {
  name: "admin",
  components: {
    SideBar,
  },
};
</script>

<style></style>
