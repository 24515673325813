<template>
  <div>
    <div class="flex items-center justify-between px-10 py-7">
      <div>
        <h1 class="text-2xl font-semibold leading-relaxed text-gray-800">
          Plans
        </h1>
      </div>
      <!-- <button
        @click="showAddModal = true"
        class="inline-flex items-center px-3 py-2.5 text-white rounded-md shadow-sm gap-x-1 bg-primary-blue hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="flex-shrink-0 w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span class="text-sm font-semibold tracking-wide">Add PCP</span>
      </button> -->
    </div>
    <div>
      <div v-if="loading" class="px-10 py-7">loading</div>
      <div v-else>
        <div v-if="isPlanNonEmpty" class="px-10">
          <table class="w-full divide-y divide-gray-300">
            <thead>
              <tr
                class="text-sm font-medium text-gray-700 border-b border-gray-200"
              >
                <th
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Sponsor
                </th>
                <th
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Plan type
                </th>
                <th class="text-sm font-semibold text-gray-900 px-3 py-3.5">
                  Total cost
                </th>
                <th class="text-sm font-semibold text-gray-900 px-3 py-3.5">
                  Total year
                </th>
                <th class="text-sm font-semibold text-gray-900 px-3 py-3.5">
                  Status
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                v-for="(plan, index) in plans"
                :key="index"
                class="transition-colors hover:bg-gray-100 group"
              >
                <td
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  <span class="mr-2">{{ plan.SponsorId.firstName }}</span>
                  <span>{{ plan.SponsorId.lastName }}</span>
                </td>
                <td
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  {{ plan.planId.name }}
                </td>
                <td
                  class="px-3 py-2 text-sm text-center text-gray-500 whitespace-nowrap"
                >
                  {{ plan.totalCost }}
                </td>
                <td
                  class="px-3 py-2 text-sm text-center text-gray-500 whitespace-nowrap"
                >
                  {{ plan.totalYear }}
                </td>
                <td
                  class="px-3 py-2 text-sm text-center text-gray-500 whitespace-nowrap"
                >
                  {{ plan.status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="px-10">no data</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan",
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    await this.doGetPlans();
    this.loading = false;
  },

  computed: {
    ...mapGetters({
      message: "MemberStore/message",
      error: "MemberStore/error",
      color: "MemberStore/color",
      plans: "MemberStore/plans",
    }),
    isPlanNonEmpty() {
      return this.plans.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      doGetPlans: "MemberStore/doGetPlans",
    }),
    goToDetailPage(id) {
      this.$router.push({
        name: "adminMemberDetail",
        params: { memberId: id },
      });
      console.log(id);
    },
    goToSetupPage(id) {
      this.$router.push({
        name: "adminMemberSetup",
        params: { memberId: id },
      });
      console.log(id);
    },
  },
};
</script>

<style></style>
