import { render, staticRenderFns } from "./editHealthCare.vue?vue&type=template&id=08e1ed2e&scoped=true&"
import script from "./editHealthCare.vue?vue&type=script&lang=js&"
export * from "./editHealthCare.vue?vue&type=script&lang=js&"
import style0 from "./editHealthCare.vue?vue&type=style&index=0&id=08e1ed2e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e1ed2e",
  null
  
)

export default component.exports