import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

// local modules
import UserStore from "./modules/user";
import PlanStore from "./modules/plan";
import PcpStore from "./modules/pcp";
import SponsorStore from "./modules/sponsor";
import HealthCareStore from "./modules/healthCare";
import MemberStore from "./modules/member";
import * as getPages from "./modules/getPages";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    getPages,
    UserStore,
    PlanStore,
    PcpStore,
    SponsorStore,
    HealthCareStore,
    MemberStore,
  },
  plugins: [vuexLocalStorage.plugin],
});
