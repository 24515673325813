import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/sytle.css";
import i18n from "./i18n";
import Toastr from "vue-toastr";

require("vue-toastr/src/vue-toastr.scss");

Vue.config.productionTip = false;

import VueShowdown from "vue-showdown";
Vue.use(VueShowdown, {
  // set default flavor of showdown
  flavor: "github",
  // set default options of showdown (will override the flavor options)
  options: {
    emoji: false,
  },
});
Vue.use(Toastr);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
