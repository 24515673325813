<template>
  <main>
    <div class="flex items-center justify-between px-10 py-7">
      <div>
        <h1 class="text-2xl font-semibold leading-relaxed text-gray-800">
          Hospital's home page
        </h1>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
