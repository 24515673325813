<template>
  <!-- ====== Partners Section Start -->
  <section class="bg-[#f2f2f2] py-20">
    <div class="container mx-auto">
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <div class="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
            <h2 class="text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
              Partners
            </h2>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-between flex-shrink-0">
        <a
          v-for="partner in partners"
          :key="partner.id"
          target="_blank"
          :href="partner.url"
          class="flex items-center justify-center py-5 mx-4 cursor-pointer"
        >
          <img
            :src="getBaseUrl() + partner.image.url"
            :alt="partner.url"
            class="h-12"
          />
        </a>
      </div>
      <!-- <div class="flex flex-wrap">
        <div class="w-full px-4">
          <div class="flex flex-wrap items-center justify-center space-x-10">
            <a
              v-for="partner in partners"
              :key="partner.id"
              target="_blank"
              :href="partner.url"
              class="flex items-center justify-center py-5 mx-4"
            >
              <img
                :src="getBaseUrl() + partner.image.url"
                :alt="partner.url"
                class="w-full h-10"
              />
            </a>
          </div>
        </div>
      </div> -->
    </div>
  </section>
  <!-- ====== Partners Section End -->
</template>

<script>
import { baseUrl } from "../../Api";
import { mapState, mapActions } from "vuex";

export default {
  name: "Partners",

  computed: mapState("getPages", ["partners"]),
  methods: {
    ...mapActions("getPages", ["fetchPartners"]),
    getBaseUrl() {
      return baseUrl;
    },
  },
  created() {
    this.fetchPartners();
  },
};
</script>

<style scoped></style>
