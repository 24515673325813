<template>
  <div>
    <select
      v-model="$i18n.locale"
      class="py-1 text-sm border border-gray-300 rounded"
      @change="handleChange($event)"
    >
      <option value="en">English</option>
      <option value="am">አማርኛ</option>
    </select>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LocaleSwitcher",

  methods: {
    ...mapActions("getPages", ["changeLanguage"]),
    handleChange(event) {
      this.changeLanguage(event.target.value);
      localStorage.setItem("lang", event.target.value);
    },
  },
};
</script>
