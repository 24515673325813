<template>
  <!-- ====== Hero Section Start -->
  <section class="px-6 relative bg-white pt-[120px] pb-[110px]">
    <div class="container mx-auto">
      <div class="flex flex-wrap -mx-4">
        <div class="w-full px-4 lg:w-5/12">
          <div class="hero-content">
            <h1
              class="mb-3 text-4xl text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-primary-blue font-bold leading-snug sm:text-[42px] lg:text-[40px] xl:text-[42px]"
            >
              {{ heros.title }}
            </h1>
            <p class="mb-8 max-w-[480px] text-base text-gray-600">
              <VueShowdown :markdown="`${heros.content}`" />
            </p>
            <ul class="flex flex-wrap items-center">
              <div>
                <router-link
                  to="/membership-plans"
                  class="font-bold leading-snug text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-primary-blue hover:bg-clip-text hover:bg-gradient-to-l hover:from-blue-600 hover:to-primary-blue"
                >
                  <!-- {{ $t("get-started") }} -->
                  Click here
                </router-link>
                <small class="mb-8 max-w-[480px] text-base text-gray-600">
                  to view membership details.
                </small>
              </div>
            </ul>
            <Hospitals />
          </div>
        </div>
        <div class="hidden px-4 lg:block lg:w-1/12"></div>
        <div class="w-full px-4 lg:w-6/12">
          <div class="lg:ml-auto lg:text-right">
            <div class="relative z-10 inline-block pt-11 lg:pt-0">
              <img
                src="../../assets/hero-image-01.png"
                alt="hero"
                class="max-w-full overflow-hidden rounded-lg lg:ml-auto"
              />
              <span class="absolute -left-8 -bottom-8 z-[-1]">
                <svg
                  width="93"
                  height="93"
                  viewBox="0 0 93 93"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2.5" cy="2.5" r="2.5" fill="#3056D3" />
                  <circle cx="2.5" cy="24.5" r="2.5" fill="#3056D3" />
                  <circle cx="2.5" cy="46.5" r="2.5" fill="#3056D3" />
                  <circle cx="2.5" cy="68.5" r="2.5" fill="#3056D3" />
                  <circle cx="2.5" cy="90.5" r="2.5" fill="#3056D3" />
                  <circle cx="24.5" cy="2.5" r="2.5" fill="#3056D3" />
                  <circle cx="24.5" cy="24.5" r="2.5" fill="#3056D3" />
                  <circle cx="24.5" cy="46.5" r="2.5" fill="#3056D3" />
                  <circle cx="24.5" cy="68.5" r="2.5" fill="#3056D3" />
                  <circle cx="24.5" cy="90.5" r="2.5" fill="#3056D3" />
                  <circle cx="46.5" cy="2.5" r="2.5" fill="#3056D3" />
                  <circle cx="46.5" cy="24.5" r="2.5" fill="#3056D3" />
                  <circle cx="46.5" cy="46.5" r="2.5" fill="#3056D3" />
                  <circle cx="46.5" cy="68.5" r="2.5" fill="#3056D3" />
                  <circle cx="46.5" cy="90.5" r="2.5" fill="#3056D3" />
                  <circle cx="68.5" cy="2.5" r="2.5" fill="#3056D3" />
                  <circle cx="68.5" cy="24.5" r="2.5" fill="#3056D3" />
                  <circle cx="68.5" cy="46.5" r="2.5" fill="#3056D3" />
                  <circle cx="68.5" cy="68.5" r="2.5" fill="#3056D3" />
                  <circle cx="68.5" cy="90.5" r="2.5" fill="#3056D3" />
                  <circle cx="90.5" cy="2.5" r="2.5" fill="#3056D3" />
                  <circle cx="90.5" cy="24.5" r="2.5" fill="#3056D3" />
                  <circle cx="90.5" cy="46.5" r="2.5" fill="#3056D3" />
                  <circle cx="90.5" cy="68.5" r="2.5" fill="#3056D3" />
                  <circle cx="90.5" cy="90.5" r="2.5" fill="#3056D3" />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Hero Section End -->
</template>

<script>
import { baseUrl } from "../../Api";
import { mapGetters, mapState, mapActions } from "vuex";

import Hospitals from "../home/Hospitals.vue";

export default {
  name: "Hero",
  components: {
    Hospitals,
  },
  data() {
    return {
      hero: [],
    };
  },
  computed: {
    ...mapState("getPages", ["heros"]),
    ...mapGetters("getPages", ["lang"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchHeros();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchHeros"]),
    getBaseUrl() {
      return baseUrl;
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(this.api);
    //     this.hero = response.data;
    //     this.loading = false;
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
  },
  created() {
    this.fetchHeros();
  },
};
</script>

<style scoped></style>
