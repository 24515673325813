import axios from "axios";
import AuthHeader from "./authHeader";
import { backendBaseUrl } from "../Api";

// Returns an axios object
export default () => {
  return axios.create({
    baseURL: backendBaseUrl,
    headers: AuthHeader.authHeader(),
  });
};
