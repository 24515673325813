<template>
  <div class="flex-1 w-full pb-8">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "plan",
};
</script>

<style></style>
