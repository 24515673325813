<template>
  <div class="px-6 text-gray-600 bg-[#F3F4F6] relative pt-[120px] pb-[110px]">
    <div class="max-w-4xl mx-auto">
      <div class="container overflow-hidden text-sm">
        <p><VueShowdown :markdown="`${glossary.content}`" /></p>
      </div>
    </div>
    <!-- <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div> -->
  </div>
</template>

<script>
import { baseUrl } from "../Api";

import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "Glossary",
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
      // loading: true,
      // items: [],
    };
  },
  created() {
    this.fetchGlossary();
  },
  computed: {
    ...mapGetters("getPages", ["lang"]),
    ...mapState("getPages", ["glossary"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchGlossary();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchGlossary"]),
    getBaseUrl() {
      return baseUrl;
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(this.api);
    //     console.log("glossary: ", response.data);
    //     // let sorted = response.data.sort((a, b) => a.rank - b.rank);
    //     this.items = response.data;
    //     this.loading = false;
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
  },
};
</script>

<style></style>
