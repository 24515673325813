import Api from "../../services/api";
export default {
  namespaced: true,
  state: {
    sponsors: [],
    error: false,
    color: "",
    errorMessage: "",
    responseMessage: "",
  },
  actions: {
    resetError({ commit }, payload) {
      console.log(payload);
      commit("RESET_ERROR_MESSAGE", payload);
    },
    async doGetAll({ commit }) {
      let response;
      try {
        response = await Api().get(`sponsor/`);
        commit("SET_RESPONSE_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_SPONSORS", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doGetOne({ commit }, id) {
      let response;
      try {
        response = await Api().get(`sponsor/${id}`);
        commit("SET_RESPONSE_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_SPONSOR", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doGetProfile({ commit }) {
      let response;
      try {
        response = await Api().get(`sponsor/profile`);
        commit("SET_RESPONSE_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_SPONSOR", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doCreate({ commit }, payload) {
      let response;
      try {
        response = await Api().post(`sponsor/`, { ...payload });
        console.log("response=>" + JSON.stringify(response));
        if (!response.data.error) {
          let localStorageValue = {
            token: response.data.record.access_token,
            role: response.data.record.role,
            _id: response.data.record.id,
          };

          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(localStorageValue)
          );
          commit("SET_ERROR", false);
        }
        console.log(response.data.message);
      } catch (e) {
        let message = e.response.data.message;
        console.log(message);
        if (typeof message === "string") {
          commit("SET_ERROR_MESSAGE", {});
          commit("SET_RESPONSE_MESSAGE", message);
        } else {
          commit("SET_ERROR_MESSAGE", message);
          commit("SET_RESPONSE_MESSAGE", "");
        }
        commit("SET_ERROR", e.response.data.error);
      }
    },
    async doEdit({ commit }, payload) {
      let response;
      try {
        let { _id, ...data } = payload;
        response = await Api().put(`sponsor/${_id}`, { ...data });
        console.log("response=>" + JSON.stringify(response));
        if (!response.data.error) {
          commit("SET_ERROR", false);
        }
        console.log(response.data.message);
      } catch (e) {
        let message = e.response.data.message;
        console.log(message);
        if (typeof message === "string") {
          commit("SET_ERROR_MESSAGE", {});
          commit("SET_RESPONSE_MESSAGE", message);
        } else {
          commit("SET_ERROR_MESSAGE", message);
          commit("SET_RESPONSE_MESSAGE", "");
        }
        commit("SET_ERROR", e.response.data.error);
      }
    },
    async doUpgradePlan({ commit }, payload) {
      let response;
      try {
        response = await Api().put("sponsor/upgrade-plan", payload);
        console.log("response=>" + JSON.stringify(response));
        if (!response.data.error) {
          commit("SET_ERROR", false);
        }
        console.log(response.data.message);
      } catch (e) {
        let message = e.response.data.message;
        console.log(message);
        if (typeof message === "string") {
          commit("SET_ERROR_MESSAGE", {});
          commit("SET_RESPONSE_MESSAGE", message);
        } else {
          commit("SET_ERROR_MESSAGE", message);
          commit("SET_RESPONSE_MESSAGE", "");
        }
        commit("SET_ERROR", e.response.data.error);
      }
    },

    async doDelete({ commit, dispatch }, id) {
      let response;
      try {
        response = await Api().delete(`sponsor/${id}`);
        console.log("response=>" + JSON.stringify(response));
        if (!response.error) {
          console.log(response.message);
          commit("SET_RESPONSE_MESSAGE", response.message);
          commit("SET_ERROR", false);
          dispatch("doGetAll");
        }
      } catch (e) {
        let message = e.response.data.message;
        commit("SET_ERROR_MESSAGE", {});
        commit("SET_RESPONSE_MESSAGE", message);

        commit("SET_ERROR", e.response.data.error);
      }
    },
    //
    async doCreateGuest({ commit }, payload) {
      let response;
      try {
        response = await Api().post(`sponsor/guest`, { ...payload });
        console.log("response=>" + JSON.stringify(response));
        commit("SET_RESPONSE_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          let localStorageValue = {
            token: response.data.record.access_token,
            role: response.data.record.role,
            _id: response.data.record.id,
          };

          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(localStorageValue)
          );
        }
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  getters: {
    errorMessage(state) {
      return state.errorMessage;
    },
    responseMessage(state) {
      return state.responseMessage;
    },
    error(state) {
      return state.error;
    },
    color(state) {
      return state.color;
    },
    sponsors(state) {
      return state.sponsors;
    },
    sponsor(state) {
      return state.sponsor;
    },
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_ERROR_MESSAGE(state, message) {
      state.errorMessage = message;
    },
    RESET_ERROR_MESSAGE(state, key) {
      state.errorMessage[key] = "";
    },
    SET_RESPONSE_MESSAGE(state, message) {
      state.responseMessage = message;
    },
    SET_SPONSORS(state, payload) {
      state.sponsors = payload;
    },
    SET_SPONSOR(state, payload) {
      state.sponsor = payload;
    },
  },
};
