<template>
  <main>
    <div class="flex items-center justify-between px-10 py-7">
      <div>
        <h1 class="text-2xl font-semibold leading-relaxed text-gray-800">
          Physicians
        </h1>
      </div>
      <button
        @click="showAddModal = true"
        class="inline-flex items-center px-3 py-2.5 text-white rounded-md shadow-sm gap-x-1 bg-primary-blue hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="flex-shrink-0 w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span class="text-sm font-semibold tracking-wide">Add PCP</span>
      </button>
    </div>
    <div>
      <div v-if="loading" class="px-10 py-7">loading</div>
      <div v-else>
        <div v-if="isPcpNonEmpty" class="px-10">
          <table class="w-full divide-y divide-gray-300">
            <thead>
              <tr
                class="text-sm font-medium text-gray-700 border-b border-gray-200"
              >
                <th
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  First Name
                </th>
                <th
                  class="text-sm font-semibold text-left text-gray-900 px-3 py-3.5"
                >
                  Last Name
                </th>

                <th class="text-sm font-semibold text-gray-900 px-3 py-3.5">
                  Phone Number
                </th>
                <th class="text-sm font-semibold text-gray-900 px-3 py-3.5">
                  Email
                </th>
                <th
                  class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                v-for="(pcp, index) in pcpByHospital"
                :key="index"
                class="transition-colors hover:bg-gray-100 group"
              >
                <td
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  {{ pcp.firstName }}
                </td>
                <td class="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                  {{ pcp.lastName }}
                </td>
                <td
                  class="px-3 py-2 text-sm text-center text-gray-500 whitespace-nowrap"
                >
                  {{ pcp.phoneNumber }}
                </td>
                <td
                  class="px-3 py-2 text-sm text-center text-gray-500 whitespace-nowrap"
                >
                  {{ pcp.email }}
                </td>

                <td
                  class="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0"
                >
                  <template
                    class="flex items-center w-20 text-gray-500 gap-x-2"
                  >
                    <button
                      @click="editPcp(index)"
                      class="p-2 hover:rounded-md hover:bg-gray-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                    <button
                      @click="deletePcp(index)"
                      class="p-2 hover:rounded-md hover:bg-gray-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="px-10">no data</div>
      </div>
    </div>
    <AddPCPModal :show="showAddModal" @close="showAddModal = false" />
    <EditPCPModal
      :pcp="selectedPcp"
      :show="showEditModal"
      @close="showEditModal = false"
    />
  </main>
</template>

<script>
import AddPCPModal from "./AddPcp.vue";
import EditPCPModal from "./editPcp.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AddPCPModal,
    EditPCPModal,
  },
  data() {
    return {
      loading: true,
      showAddModal: false,
      showEditModal: false,
      selectedPcp: {},
    };
  },
  async created() {
    let loggedInUser = localStorage.getItem("loggedInUser");
    if (loggedInUser) {
      let parsedUser = JSON.parse(localStorage.getItem("loggedInUser"));
      console.log("parsed user", parsedUser);
    }
    this.loading = true;
    await this.doGetAllByHospital();
    this.loading = false;
  },

  computed: {
    ...mapGetters({
      message: "PcpStore/message",
      error: "PcpStore/error",
      color: "PcpStore/color",
      pcpByHospital: "PcpStore/pcpByHospital",
    }),
    isPcpNonEmpty() {
      return this.pcpByHospital.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      doGetAllByHospital: "PcpStore/doGetAllByHospital",
      doDelete: "PcpStore/doDelete",
    }),
    editPcp(index) {
      this.selectedPcp = this.pcpByHospital[index];
      this.showEditModal = true;
    },
    async deletePcp(index) {
      await this.doDelete(this.pcpByHospital[index]._id);
      if (this.error) this.$toastr.e(this.message);
      else this.$toastr.s(this.message);
    },
  },
};
</script>

<style></style>
