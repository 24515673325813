<template>
  <main>
    <!-- <button
      style="float: right; margin-top: 5%"
      @click="updateMember"
      class="px-3 py-2.5 text-white rounded-md shadow-sm gap-x-1 bg-gray-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
    >
      <span class="text-sm font-semibold tracking-wide">update profile</span>
    </button> -->

    <div class="flex items-center justify-between px-10 py-7">
      <div>
        <h1 class="text-2xl font-semibold leading-relaxed text-gray-800"></h1>
        <div class="grid grid-cols-2 gap-8">
          <div>
            <span>Name</span>
          </div>
          <div>
            <span>{{ member.name }}</span>
          </div>
          <div>
            <span>Email </span>
          </div>
          <div>
            <span>{{ member.email }}</span>
          </div>
          <div>
            <span>{{ member.country }}</span>
          </div>
          <div>
            <span>{{ member.country }}</span>
          </div>

          <div>
            <span>city </span>
          </div>
          <div>
            <span>{{ member.city }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      member: "MemberStore/member",
    }),
  },
  methods: {
    ...mapActions({
      doGetProfile: "MemberStore/doGetProfile",
    }),
    updateMember() {},
  },
  async created() {
    await this.doGetProfile();
    console.log(this.sponsor);
  },
};
</script>

<style></style>
