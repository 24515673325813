<template>
  <main>
    <div>
      <div class="flex items-center justify-between px-10 py-7">
        <div>
          <h1 class="text-2xl font-semibold leading-relaxed text-gray-800">
            Member
          </h1>
        </div>
      </div>
      <div v-if="loading" class="px-10 py-7">data is loading</div>
      <div v-else>
        <div v-if="isDataNonEmpty">
          <table class="w-full border-b border-gray-200">
            <thead>
              <tr
                class="text-sm font-medium text-gray-700 border-b border-gray-200"
              >
                <td class="pl-10">
                  <div class="flex items-center gap-x-4">
                    <span> Name</span>
                  </div>
                </td>
                <td class="px-4 py-4 text-center">Phone Number</td>
                <td class="px-4 py-4 text-center">Account Set up</td>
                <td class="px-4 py-4 text-center">Status</td>
                <td class="px-4 py-4 text-center">Action</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(member, index) in members"
                :key="index"
                class="transition-colors hover:bg-gray-100 group"
              >
                <td class="flex items-center py-4 pl-10 gap-x-4">
                  {{ member.name }}
                </td>
                <td class="font-medium text-center">
                  {{ member.phoneNumber }}
                </td>
                <td class="font-medium text-center">{{ member.settedup }}</td>
                <td class="font-medium text-center">{{ member.status }}</td>

                <td class="flex justify-center">
                  <div>
                    <div
                      v-if="member.settedup"
                      class="flex items-center w-20 text-gray-500 gap-x-2"
                    >
                      <button
                        class="px-2 py-2 text-sm border border-gray-400 rounded"
                        @click="goToDetailPage(member._id)"
                      >
                        Detail
                      </button>
                      <button
                        class="px-2 py-2 text-sm border border-gray-400 rounded"
                        @click="editMember(index)"
                      >
                        edit
                      </button>
                    </div>
                    <div v-else class="space-x-4">
                      <button
                        class="px-2 py-2 text-sm border border-gray-400 rounded"
                        @click="goToSetupPage(member._id)"
                      >
                        Set up account
                      </button>
                      <button
                        class="px-2 py-2 text-sm border border-gray-400 rounded"
                        @click="goToDetailPage(member._id)"
                      >
                        Detail
                      </button>

                      <button
                        class="px-2 py-2 text-sm border border-gray-400 rounded"
                        @click="editMember(index)"
                      >
                        edit
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="px-10">No member(s)</div>
      </div>
    </div>
    <EditMemberModal
      :show="showEditModal"
      @close="showEditModal = false"
      :member="selectedMember"
    />
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditMemberModal from "./EditMember.vue";
export default {
  name: "plan",
  components: {
    EditMemberModal,
  },
  data() {
    return {
      loading: true,
      showEditModal: false,
      selectedMember: {},
    };
  },
  async created() {
    this.loading = true;
    await this.doGetAll();
    this.loading = false;
  },

  computed: {
    ...mapGetters({
      message: "MemberStore/message",
      error: "MemberStore/error",
      color: "MemberStore/color",
      members: "MemberStore/members",
    }),
    isDataNonEmpty() {
      return this.members.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      doGetAll: "MemberStore/doGetAll",
      doEdit: "MemberStore/doEdit",
    }),
    editMember(index) {
      this.selectedMember = { ...this.members[index] };
      this.showEditModal = true;
    },
    goToDetailPage(id) {
      this.$router.push({
        name: "adminMemberDetail",
        params: { memberId: id },
      });
      console.log(id);
    },
    goToSetupPage(id) {
      this.$router.push({
        name: "adminMemberSetup",
        params: { memberId: id },
      });
      console.log(id);
    },
  },
};
</script>

<style></style>
