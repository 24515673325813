<template>
  <section class="px-6 relative pt-[120px] pb-[110px]">
    <div class="container mx-auto">
      <div>
        <div
          class="grid grid-cols-1 gap-8 mx-auto md:grid-cols-2 lg:grid-cols-3"
        >
          <div
            class="overflow-hidden text-gray-600 rounded-sm"
            v-for="service in services"
            :key="service.id"
          >
            <div
              v-if="service.image"
              class="h-full overflow-hidden bg-[#F3F3F5] rounded-lg"
            >
              <img
                class="object-cover object-center w-full h-80"
                :src="getBaseUrl() + service.image.url"
                alt="services"
              />
              <div class="px-4 py-6">
                <p class="leading-relaxed">
                  <VueShowdown :markdown="service.content" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div> -->
    </div>
  </section>
</template>

<script>
import { baseUrl } from "../Api";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Services",
  data() {
    return {
      // items: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  created() {
    this.fetchServices();
  },
  computed: {
    ...mapGetters("getPages", ["lang"]),
    ...mapState("getPages", ["services"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchServices();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchServices"]),
    getBaseUrl() {
      return baseUrl;
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(this.api);
    //     console.log("services: ", response.data);
    //     this.items = response.data.filter((s) => s.image);
    //     this.loading = false;
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },
  },
};
</script>

<style></style>
