<template>
  <div class="flex-1 w-full pb-8">my expenses</div>
</template>

<script>
export default {
  name: "member",
};
</script>

<style></style>
