<template>
  <!-- ====== FAQ Section Start -->
  <section class="relative z-20 overflow-hidden bg-white pt-[120px] pb-[110px]">
    <div class="container mx-auto">
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <div class="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
            <span
              class="block mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] text-primary-blue"
            >
              FAQ
            </span>
            <p class="text-base text-body-color">
              {{ $t("commonly-asked-questions") }}
            </p>
            <p class="text-base text-body-color">
              {{ $t("answer-not-found") }}
              <a href="mailto:info@wastina.com" class="text-primary-blue">
                info@wastina.com
              </a>
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap -mx-4">
        <div class="w-full px-4">
          <!-- grid grid-cols-1 gap-3 mx-auto md:grid-cols-2 2xl:grid-cols-2 -->
          <div class="grid max-w-screen-lg gap-3 px-5 mx-auto">
            <div
              v-for="(fa, index) in faq"
              :key="fa.id"
              class="single-faq w-full rounded-lg border border-[#F3F4FE] p-4 bg-[#FeFeFe]"
            >
              <button
                class="flex items-center w-full text-left faq-btn"
                @click="showFaq(index)"
              >
                <div class="w-full">
                  <h4 class="text-lg font-medium text-gray-900">
                    {{ fa.question }}
                  </h4>
                </div>
                <div
                  class="flex items-center justify-center w-10 h-8 rounded-lg text-primary"
                >
                  <svg
                    v-if="openFaq === index"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 transition duration-300 stroke-2 text-primary-blue"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                  <svg
                    v-if="openFaq !== index"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 stroke-2 text-primary-blue"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </button>
              <div v-if="openFaq === index" class="faq-content pr-[62px]">
                <p class="py-3 text-base leading-relaxed text-gray-700">
                  <VueShowdown :markdown="`${fa.answer}`" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="absolute top-0 bottom-0 right-0 z-[-1]">
      <svg
        width="1440"
        class="h-full"
        viewBox="0 0 1440 886"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="1308.65"
            y1="1142.58"
            x2="602.827"
            y2="-418.681"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3056D3" stop-opacity="0.36" />
            <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
            <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </section>
  <!-- ====== FAQ Section End -->
</template>

<script>
import { baseUrl } from "../../Api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "FAQ",
  data() {
    return {
      openFaq: null,
      // faqs: [],
    };
  },
  created() {
    this.fetchFaq();
  },
  computed: {
    ...mapGetters("getPages", ["lang"]),
    ...mapState("getPages", ["faq"]),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchFaq();
    },
  },
  methods: {
    ...mapActions("getPages", ["fetchFaq"]),
    showFaq(index) {
      this.openFaq === index ? (this.openFaq = null) : (this.openFaq = index);
      // if (this.openFaq === index) {
      //   this.openFaq = null;
      // } else {
      //   this.openFaq = index;
      // }
    },

    getBaseUrl() {
      return baseUrl;
    },
  },
};
</script>

<style scoped></style>
