import Api from "../../services/api";
export default {
  namespaced: true,
  state: {
    members: [],
    memberByHospital: [],
    error: false,
    color: "",
    member: {},
    plans: [],
    profile: {},
    errorMessage: "",
    responseMessage: "",
  },
  actions: {
    resetError({ commit }, payload) {
      console.log(payload);
      commit("RESET_ERROR_MESSAGE", payload);
    },
    async doGetAllByHospital({ commit }) {
      let response;
      try {
        response = await Api().get(`member/byHospital/`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_MEMBER_BY_HOSPITAL", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doGetAll({ commit }) {
      let response;
      try {
        response = await Api().get(`member`);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_MEMBERS", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doGetProfile({ commit }) {
      let response;
      try {
        response = await Api().get(`member/profile`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          console.log(response.data.record);
          commit("SET_PROFILE", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doEdit({ commit, dispatch }, payload) {
      let response;
      try {
        let { _id, ...data } = payload;
        response = await Api().put(`member/${_id}`, { ...data });
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAll");
        }
      } catch (e) {
        commit("SET_ERROR", e.response.data.error);
        commit("SET_MESSAGE", e.response.data.message);
      }
    },
    async doGetBySponsor({ commit }) {
      let response;
      try {
        response = await Api().get(`member/bySponsor`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_MEMBERS_BY_SPONSOR", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doGetPlans({ commit }) {
      let response;
      try {
        response = await Api().get(`member/myplans`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_PLANS", response.data.record[0].plan);
          console.log(response.data.record[0].plan);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doGetSingle({ commit }, id) {
      let response;
      try {
        response = await Api().get(`member/${id}`);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_MEMBER", response.data.record);
        }
      } catch (e) {
        console.log("error", e);
      }
    },

    async doCreate({ commit, dispatch }, payload) {
      let response;
      console.log("payload =>" + JSON.stringify(payload));
      try {
        response = await Api().post(`member`, { ...payload });
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          let localStorageValue = {
            memberId: response.data.record._id,
          };

          localStorage.setItem("memberId", JSON.stringify(localStorageValue));
          dispatch("doGetAll");
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async doCreateBySponsor({ commit, dispatch }, payload) {
      let response;
      console.log("payload =>" + JSON.stringify(payload));
      try {
        response = await Api().post(`member/bySponsor`, { ...payload });
        commit("SET_ERROR", response.data.error);
        console.log(response.data.error);
        if (!response.data.error) {
          let localStorageValue = {
            memberId: response.data.record._id,
          };

          localStorage.setItem("memberId", JSON.stringify(localStorageValue));
          dispatch("SET_MEMBERS_BY_SPONSOR");
        }
      } catch (e) {
        let message = e.response.data.message;
        if (typeof message === "string") {
          commit("SET_ERROR_MESSAGE", {});
          commit("SET_RESPONSE_MESSAGE", message);
        } else {
          commit("SET_ERROR_MESSAGE", message);
          commit("SET_RESPONSE_MESSAGE", "");
        }
        commit("SET_ERROR", e.response.data.error);
      }
    },
    async doUpdateStatus({ commit, dispatch }, payload) {
      let response;
      console.log("payload =>" + JSON.stringify(payload));
      try {
        response = await Api().put(
          `member/${payload.mId}/status/${payload.planId}`
        );
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAll");
        }
      } catch (e) {
        console.log("error", e);
      }
    },

    async doSetup({ commit, dispatch }, payload) {
      let response;
      console.log("payload =>" + JSON.stringify(payload));
      try {
        response = await Api().post(`member/setup/${payload.mId}`, {
          password: payload.password,
        });
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          dispatch("doGetAll");
        }
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  getters: {
    errorMessage(state) {
      return state.errorMessage;
    },
    responseMessage(state) {
      return state.responseMessage;
    },
    error(state) {
      return state.error;
    },
    color(state) {
      return state.color;
    },
    members(state) {
      return state.members;
    },
    profile(state) {
      return state.profile;
    },
    membersBySponsor(state) {
      return state.membersBySponsor;
    },
    member(state) {
      return state.member;
    },
    plans(state) {
      return state.plans;
    },
    memberByHospital(state) {
      return state.memberByHospital;
    },
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_ERROR_MESSAGE(state, message) {
      state.errorMessage = message;
    },
    RESET_ERROR_MESSAGE(state, key) {
      state.errorMessage[key] = "";
    },
    SET_RESPONSE_MESSAGE(state, message) {
      state.responseMessage = message;
    },
    SET_MEMBERS(state, payload) {
      state.members = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_MEMBER(state, payload) {
      state.member = payload;
    },
    SET_MEMBER_BY_HOSPITAL(state, payload) {
      state.memberByHospital = payload;
    },
    SET_PLANS(state, payload) {
      state.plans = payload;
    },
    SET_MEMBERS_BY_SPONSOR(state, payload) {
      state.membersBySponsor = payload;
    },
  },
};
