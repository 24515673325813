<template>
  <div
    v-if="show"
    class="fixed inset-0 z-50 flex items-center justify-center w-full overflow-y-auto"
    @click.self="close"
  >
    <div
      @click.self="close_modal()"
      class="fixed w-full h-screen bg-gray-900 modal-overlay opacity-70"
    ></div>

    <div
      class="z-50 w-full mx-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div class="px-8 py-4 text-left modal-content">
        <div class="flex items-center justify-between py-2 gap-x-10">
          <h6 class="text-xl font-bold text-primary-blue">
            Edit HealthCare Provider
          </h6>
          <div class="p-1 group hover:p-1 hover:bg-gray-100 hover:rounded-full">
            <svg
              @click="close_modal()"
              class="w-8 h-8 text-gray-600 cursor-pointer stroke-[1.5] group-hover:text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <form @submit.prevent="submit_form">
          <div class="w-full bg-white">
            <div class="py-4">
              <div class="mb-6">
                <label
                  for="name"
                  class="block text-xs font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  v-model="healthCareSchema.name"
                  placeholder="Name"
                  required
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label for="" class="block text-xs font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  required
                  type="text"
                  v-model="healthCareSchema.phoneNumber"
                  placeholder="Phone number"
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label for="" class="block text-xs font-medium text-gray-700">
                  Specialty
                </label>
                <input
                  required
                  type="text"
                  v-model="healthCareSchema.specialty"
                  placeholder="Specialty"
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="flex space-x-5">
                <div class="mb-6">
                  <label
                    for="country"
                    class="block text-xs font-medium text-gray-700"
                  >
                    Country
                  </label>
                  <input
                    required
                    type="text"
                    v-model="healthCareSchema.country"
                    placeholder="Country"
                    class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                  />
                </div>
                <div class="mb-6">
                  <label
                    for="city"
                    class="block text-xs font-medium text-gray-700"
                  >
                    City
                  </label>
                  <input
                    required
                    type="text"
                    v-model="healthCareSchema.city"
                    placeholder="City"
                    class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                  />
                </div>
              </div>
              <div class="mb-6">
                <label for="" class="block text-xs font-medium text-gray-700">
                  Email
                </label>
                <input
                  required
                  type="email"
                  v-model="healthCareSchema.email"
                  placeholder="Email"
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label for="" class="block text-xs font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  v-model="healthCareSchema.password"
                  placeholder="Password"
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
            </div>
            <div class="flex items-center justify-end gap-x-6">
              <button
                @click="close_modal()"
                class="px-4 py-2.5 text-sm font-semibold transition border rounded-md cursor-pointer hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                type="submit"
                value="SignIn"
                class="px-4 py-2.5 text-sm font-semibold text-white transition border rounded-md cursor-pointer bg-primary-blue hover:bg-blue-900"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddPlan",

  props: {
    show: Boolean,
    hospital: Object,
  },
  data() {
    return {
      showAddModal: false,

      healthCareSchema: {
        name: "",
        email: "",
        phoneNumber: "",
        city: "",
        country: "",
        specialty: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      message: "HealthCareStore/message",
      error: "HealthCareStore/error",
      color: "HealthCareStore/color",
      plans: "HealthCareStore/plans",
    }),
  },

  methods: {
    ...mapActions({
      doEdit: "HealthCareStore/doEdit",
    }),
    close_modal() {
      this.$emit("close");
      document.body.classList.remove("overflow-hidden");
    },
    async submit_form() {
      let data = {
        _id: this.healthCareSchema._id,
        name: this.healthCareSchema.name,
        email: this.healthCareSchema.email,
        phoneNumber: this.healthCareSchema.phoneNumber,
        specialty: this.healthCareSchema.specialty,
        city: this.healthCareSchema.city,
        country: this.healthCareSchema.country,
      };
      if (this.healthCareSchema.password !== "")
        data.password = this.healthCareSchema.password;
      await this.doEdit(data);

      if (this.error) {
        this.$toastr.e(this.message);
      } else {
        this.close_modal();
      }
    },
  },
  watch: {
    hospital: function () {
      this.healthCareSchema = { ...this.hospital };
    },
  },
};
</script>

<style scoped>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
