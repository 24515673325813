vue.
<template>
  <section class="bg-[#F4F7FF] py-20">
    <div class="max-w-5xl px-4 mx-auto">
      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div class="px-8 py-8 text-left modal-content">
          <div class="flex justify-end"></div>
          <div class="flex items-center mb-3">
            <p class="text-2xl font-bold">Login</p>
          </div>
          <form @submit.prevent>
            <div class="flex flex-wrap -mx-4">
              <div class="w-full px-4">
                <div class="py-16 text-center bg-white">
                  <form @submit.prevent>
                    <div class="mb-6">
                      <input
                        type="text"
                        placeholder="Email"
                        v-model="loginSchema.email"
                        v-on:change="resetErrorField('email')"
                        class="w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none border-gray-300 focus:border-primary focus-visible:shadow-none"
                      />
                      <span
                        class="text-red-600"
                        v-if="error && errorMessage.email !== ''"
                        >{{ errorMessage.email }}</span
                      >
                    </div>
                    <div class="mb-6">
                      <input
                        type="password"
                        v-model="loginSchema.password"
                        v-on:change="resetErrorField('password')"
                        placeholder="Password"
                        class="border-gray-300 w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
                      />
                      <span
                        class="text-red-600"
                        v-if="error && errorMessage.password !== ''"
                        >{{ errorMessage.password }}</span
                      >
                    </div>
                    <div class="mb-10">
                      <button
                        type="submit"
                        value="Sign In"
                        class="w-full px-5 py-3 text-base text-white transition bg-primary-blue border rounded-md cursor-pointer border-primary hover:bg-opacity-90"
                        @click="submit_login"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                  <button
                    @click="showModal = true"
                    class="mb-2 inline-block text-base text-[#adadad] hover:text-blue-800 cursor-pointer"
                  >
                    Forget Password?
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ForgotPassword :show="showModal" @close="showModal = false">
    </ForgotPassword>
  </section>
</template>

<script>
import ForgotPassword from "@/components/modal/ForgotPassword.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Login",
  components: {
    ForgotPassword,
  },
  props: {
    show: Boolean,
    url: String,
    label: String,
  },
  data() {
    return {
      showModal: false,
      fullName: "",
      phone: "",
      email: "",
      title: "",
      company: "",
      loginSchema: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: "UserStore/errorMessage",
      responseMessage: "UserStore/responseMessage",
      error: "UserStore/error",
      color: "UserStore/color",
    }),
  },

  methods: {
    ...mapActions({
      login: "UserStore/login",
      resetError: "UserStore/resetError",
    }),
    resetErrorField(key) {
      this.resetError(key);
    },
    async submit_login() {
      let payload = {
        data: this.loginSchema,
      };
      await this.login(payload);
      if (this.error && this.responseMessage) {
        this.$toastr.e(this.responseMessage);
      } else if (!this.error) {
        setTimeout(() => window.location.reload(), 5000);
        this.$toastr.s(this.responseMessage);
      }
    },
  },
};
</script>

<style>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
