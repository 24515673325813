<template>
  <div
    v-if="show"
    class="fixed inset-0 z-50 flex items-center justify-center w-full overflow-y-auto"
  >
    <div
      @click.self="close_modal()"
      class="fixed w-full h-screen bg-gray-900 modal-overlay opacity-70"
    ></div>

    <div
      class="z-50 w-full mx-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div class="px-8 py-4 text-left modal-content">
        <div class="flex items-center justify-between py-2 gap-x-10">
          <h6 class="text-xl font-bold text-primary-blue">Edit plan</h6>
          <div class="p-1 group hover:p-1 hover:bg-gray-100 hover:rounded-full">
            <svg
              @click="close_modal()"
              class="w-8 h-8 text-gray-600 cursor-pointer stroke-[1.5] group-hover:text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <form @submit.prevent="submit_form">
          <div class="w-full bg-white">
            <div class="py-4">
              <div class="mb-6">
                <label
                  for="name"
                  class="block text-xs font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  v-model="planSchema.name"
                  placeholder="Plan name"
                  required
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label
                  for="name"
                  class="block text-xs font-medium text-gray-700"
                >
                  Title
                </label>
                <input
                  type="text"
                  v-model="planSchema.title"
                  placeholder="Plan name"
                  required
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <label
                  for="price"
                  class="block text-xs font-medium text-gray-700"
                >
                  Price
                </label>
                <input
                  type="number"
                  v-model="planSchema.price"
                  placeholder="Price"
                  required
                  class="w-full mt-1 placeholder-gray-300 border-gray-200 rounded-md shadow-sm"
                />
              </div>
              <div class="mb-6">
                <div class="flex items-center justify-between mb-4">
                  <label for="description" class="flex text-gray-600">
                    Description
                  </label>
                  <div
                    @click="showAddDescriptionField"
                    class="flex items-center px-4 py-1 text-sm text-gray-600 border border-gray-300 rounded cursor-pointer"
                  >
                    <span class="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </span>
                    New item
                  </div>
                </div>

                <div class="flex flex-col">
                  <ul class="space-y-1 list-disc ml-4">
                    <li
                      v-for="(description, index) in planSchema.descriptions"
                      :key="index"
                      class="text-gray-600 text-sm"
                    >
                      <div class="flex items-center justify-between">
                        {{ description.item }}
                        <span
                          @click="removeItemfromDescriptions(index)"
                          class="text-sm hover:bg-red-200 rounded-full p-1 font-semibold text-red-600 cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 fill-current"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </span>
                      </div>
                    </li>
                  </ul>
                  <!-- <div
                    v-for="(description, index) in planSchema.descriptions"
                    :key="index"
                  >
                    <div class="flex justify-between">
                      <span class="text-gray-600 text-sm">{{
                        description.item
                      }}</span>
                      <div
                        @click="removeItemfromDescriptions(index)"
                        class="text-sm font-semibold text-red-600 cursor-pointer"
                      >
                        delete
                      </div>
                    </div>
                  </div> -->
                  <div v-if="showAddDescription" class="flex items-center mt-2">
                    <input
                      type="text"
                      v-model="item"
                      placeholder="Description"
                      class="border-gray-300 mr-2 w-full rounded-md border bg-[#FCFDFE] py-1 px-4 text-base placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
                    />
                    <button @click="addItemtoDescription">
                      <span
                        class="text-green-500 border border-green-500 py-1.5 px-4 rounded-md text-base"
                      >
                        <!-- <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg> -->
                        Add
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-end gap-x-6">
              <div
                @click="close_modal()"
                class="px-4 py-2.5 text-sm font-semibold transition border rounded-md cursor-pointer hover:bg-gray-100"
              >
                Cancel
              </div>
              <button
                type="submit"
                value="SignIn"
                class="px-4 py-2.5 text-sm font-semibold text-white transition border rounded-md cursor-pointer bg-primary-blue hover:bg-blue-900"
              >
                Add plan
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddPlan",

  props: {
    show: Boolean,
  },
  data() {
    return {
      showAddModal: false,
      showAddDescription: false,
      descriptions: [],
      item: "",
      planSchema: {
        name: "",
        title: "",
        price: "",
        descriptions: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      message: "PlanStore/message",
      error: "PlanStore/error",
      color: "PlanStore/color",
      plans: "PlanStore/plans",
    }),
  },

  methods: {
    ...mapActions({
      doCreate: "PlanStore/doCreate",
    }),
    close_modal() {
      this.$emit("close");
    },
    showAddDescriptionField() {
      this.showAddDescription = true;
    },
    addItemtoDescription() {
      this.planSchema.descriptions.push({
        item: this.item,
      });
      this.showAddDescription = false;
      this.item = "";
    },
    removeItemfromDescriptions(index) {
      this.planSchema.descriptions.splice(index, 1);
    },
    async submit_form() {
      await this.doCreate(this.planSchema);
      console.log(this.error);
      if (this.error) {
        this.$toastr.e(this.message);
      } else {
        this.close_modal();
        this.planSchema = {};
      }
    },
  },
};
</script>

<style scoped>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
