<template>
  <section>
    <div class="pt-16 clients">
      <h6 class="flex items-center mb-2 text-xs font-normal text-gray-500">
        You Can Find Our Services In
        <span class="ml-2 inline-block h-[1px] w-8 bg-gray-500"></span>
      </h6>
      <div class="flex items-center">
        <div class="py-3 mr-4" v-for="hospital in hospitals" :key="hospital.id">
          <a
            target="_blank"
            :href="hospital.url"
            class="mx-4 flex w-[100px] items-center justify-center py-5"
          >
            <img
              :src="getBaseUrl() + hospital.image.url"
              :alt="hospital.name"
              class="w-full"
            />
          </a>
          <!-- <img
            :src="getBaseUrl() + hospital.image.url"
            :alt="hospital.name"
            class="h-16"
          /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { baseUrl } from "../../Api";
import { mapState, mapActions } from "vuex";
export default {
  name: "Hospitals",
  computed: mapState("getPages", ["hospitals"]),
  methods: {
    ...mapActions("getPages", ["fetchHospitals"]),
    getBaseUrl() {
      return baseUrl;
    },
  },
  created() {
    this.fetchHospitals();
  },
};
</script>

<style scoped></style>
